export const mapFilterSuggestions = (filterSuggestions) => {
    if (filterSuggestions ) {
        let response = [];

        filterSuggestions.map((suggestion) => (
            response.push({id: suggestion.id.toString(), label: suggestion.title, value: suggestion.title, type: suggestion.type, permalink: suggestion.permalink})
        ));

        return response;
    }
    return [];
};