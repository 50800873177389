import React, {Component} from 'react';
import {connect} from 'react-redux';
import AppinterListItem from './appointerListItem';
import {injectIntl} from "react-intl";
import {createGetFilteredAppointersAction} from "../../actions/Appointers.actions";

class AppointersList extends Component {

    renderAppointersList  = () => {
        return this.props.appointers.length ?
            this.props.appointers.map((appointer) => (
                <AppinterListItem key={appointer.erp_sanasoft_id} appointer={appointer} />
            ))
            :
            null
    };

    render() {
        return (
            <div className="appointerListResults">
                <div className="appointersList">
                    {
                        this.renderAppointersList()
                    }
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    appointers: state.appointers,
});

export default injectIntl(connect(
    mapStateToProps,
    {
        createGetFilteredAppointersAction,
    }
)(AppointersList));

