import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import {
    createGetAppointersReceivedAction,
    createSetAppointersListLoadingAction,
    createSetAppointersSpecialtiesReceivedAction,
    GET_APPOINTERS,
    GET_FILTERED_APPOINTERS,
    GET_APPOINTERS_SPECIALTIES,
} from "../actions/Appointers.actions";
import {genericErrorHandler} from "./Application.saga";
import * as Appointers from '../api/Appointers.api';

function* getAppointers() {
    yield put(createSetAppointersListLoadingAction(true));
    const response  = yield call(Appointers.getAppointers);
    yield put(createGetAppointersReceivedAction(response.data.data));
    yield put(createSetAppointersListLoadingAction(false));
}

function* getFilteredAppointers(action) {
    yield put(createSetAppointersListLoadingAction(true));
    const response  = yield call(Appointers.getFilteredAppointers, action.payload);
    yield put(createGetAppointersReceivedAction(response.data.data));
    yield put(createSetAppointersListLoadingAction(false));

}

function* getAppointersSpecialties() {
    const response = yield call(Appointers.getAppointersSpecialties);
    yield put(createSetAppointersSpecialtiesReceivedAction(response.data.data));
}

export function* apppointersSaga() {
    yield takeLatest(GET_APPOINTERS, genericErrorHandler(getAppointers));
    yield takeLatest(GET_FILTERED_APPOINTERS, genericErrorHandler(getFilteredAppointers));
    yield takeLatest(GET_APPOINTERS_SPECIALTIES, genericErrorHandler(getAppointersSpecialties));
}
