import React, {Fragment} from 'react';
import {Link} from "react-router-dom";
import doctor from '../../images/one_doctor.svg';
import clinic from '../../images/hospital.svg';
import {returnCompleteAddress} from "../../utils/address";
import {getAppointerSpecialties} from "../../utils/specialties";

const AppinterDetails = (props) => {
    const imagePlaceholder = parseInt(props.appointer.appointer.type) === 1 ? doctor : clinic;

    let embeddedAppointerUrl  = 'embedded/';

    const permalink = props.appointer.permalink || props.appointer.appointer.metadata.permalink;
    embeddedAppointerUrl += permalink.split('/')[1];

    const url = props.isEmbedded ? embeddedAppointerUrl : permalink;
    return (
        <Fragment>
            <div className="col-12 appointersListItem p-0">
                <div className="p-0 imageContainer">
                    <img className="appointersListItemImage" src={props.appointer.appointer.profile_image || imagePlaceholder} alt={props.appointer.appointer.title} />
                </div>
                <div className="appointersListItemDetails">
                    <div className="appointersListItemName">
                        {
                            props.preview ?
                                <span className="appointerName"> {props.appointer.appointer.title} {props.title ? ` > ${props.title}` : null}</span> :
                                <div className="appointerName">
                                    <Link to={`/${url}`}>
                                        <span> {props.appointer.appointer.title}</span>
                                    </Link>

                                    <span > {props.title ? ` > ${props.title}` : null}</span>
                                </div>
                        }

                        {
                            props.appointer.appointer.addresses.length ?
                                <span className="appointersListItemAddress text-right"> {returnCompleteAddress(props.appointer.appointer.addresses[0])} <i className="locationPin" /></span> : null
                        }
                    </div>
                    <ul className="appointersListItemList">
                        <li>
                            <span className="appointersListItemValue">
                                {getAppointerSpecialties(props.appointer.appointer.specialties)}
                            </span>
                        </li>
                    </ul>
                </div>
            </div>
        </Fragment>
    );
};

export default AppinterDetails;
