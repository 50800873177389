import {
  SUBMIT_CONTACT_RECEIVED,
} from '../actions/Contact.actions';

const initialState = null;

const ContactReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CONTACT_RECEIVED:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default ContactReducer;
