import { GET_APPOINTER_RESOURCE_TYPES_RECEIVED } from "../actions/Appointer.actions";

const initialState = [];

const ResourceTypesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTER_RESOURCE_TYPES_RECEIVED:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export default ResourceTypesReducer;
