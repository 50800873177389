import React, {Component} from 'react';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Form } from "react-bootstrap";
import {GoogleMap, InfoWindow, LoadScript, Marker} from "@react-google-maps/api";
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { createGetAppointersAction } from "../../actions/Appointers.actions";
import AppointerItem from '../AppointerMapItem/appointerItem';
import pin from '../../images/locationPin.svg';
import {GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_DEFAULT_CENTER} from "../../config/general";

const EXTRA_TRANSLATIONS = defineMessages({
    specialityPlaceholder: {
        id: 'locationMap.speciality',
        defaultMessage: 'medical speciality',
    },
    areaPlaceholder: {
        id: 'locationMap.area',
        defaultMessage: 'select area',
    },
});
class ModalLocation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAppointerMarker: null,
            showInfoBox: false,
        }
    }
    componentWillMount() {
        this.props.createGetAppointersAction();
    }

    showInfoBox = (appointer) => {
        this.setState({
            selectedAppointerMarker: appointer,
            showInfoBox: true,
        })
    };

    closeInfoBox = () => {
        this.setState({
            selectedAppointerMarker: null,
            showInfoBox: false,
        })
    };

    renderAppointers() {
        return this.props.appointers.map( (appointer, index) => (
            <Marker
                key={index}
                position={ appointer.coordinates }
                icon={pin}
                onClick={(e) => {
                    this.showInfoBox(appointer);
                }}
            />
        ))
    }

    render() {
        return (
                <div className="modalLocation">
                    <Modal.Header>
                        <Container>
                            <Row className="p-0">
                                <Col md={5}>
                                    <span className="modal-title"><FormattedMessage id="locationModal.title" defaultMessage="Find your doctor near you on the map"/></span>
                                </Col>
                                <Col md={6}>
                                    <Form>
                                        <Row className="mb-0">
                                            <Col>
                                                <Form.Control as="select" className="selectMedicalSpeciality">
                                                    <option>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.specialityPlaceholder)}</option>
                                                    <option>2</option>
                                                    <option>3</option>
                                                    <option>4</option>
                                                    <option>5</option>
                                                </Form.Control>
                                            </Col>
                                            <Col>
                                                <Form.Control as="select" className="selectArea" placeholder="area">
                                                    <option>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.areaPlaceholder)}</option>
                                                    <option value={5}>Area: 5km</option>
                                                    <option value={10}>Area: 10km</option>
                                                    <option value={15}>Area: 15km</option>
                                                    <option value={20}>Area: 20km</option>
                                                </Form.Control>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                                <Col md={1}>
                                    <span className="closeBtn" onClick={this.props.modal.onHide}>
                                        <span aria-hidden="true">&times;</span>
                                    </span>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Modal.Body className="p-0">
                        <Container className="p-0">
                            <div className="map">
                                <LoadScript
                                    id="script-loader"
                                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                                >
                                    <GoogleMap
                                        id='example-map'
                                        zoom={10}
                                        center={GOOGLE_MAPS_DEFAULT_CENTER}
                                    >
                                        <Marker
                                            position={{lat: 48.2082, lng: 16.3738}}
                                        />
                                        { this.renderAppointers() }
                                        {
                                            this.state.showInfoBox ?
                                                <InfoWindow
                                                    onLoad={infoWindow => {
                                                        //console.log('infoWindow: ', infoWindow)
                                                    }}
                                                    position={this.state.selectedAppointerMarker.coordinates}
                                                    onCloseClick = {this.closeInfoBox}
                                                >
                                                    <AppointerItem appointer={this.state.selectedAppointerMarker} />
                                                </InfoWindow> :
                                                null
                                        }
                                    </GoogleMap>
                                </LoadScript>
                            </div>
                        </Container>
                    </Modal.Body>
                </div>
        );
    }
}

const mapStateToProps = (state) => ({
    appointers: state.appointers,
});

export default injectIntl(connect(
    mapStateToProps,
    {
        createGetAppointersAction,
    }
)(ModalLocation));
