import React, {useState} from 'react';

const  BootstrapTextarea = ({field, form, meta, ...props}) => {
    const [length, setLength] = useState(500);
    const changeTextareaLength = (e) => {
        setLength( 500 - e.target.value.length);
    };

    return (
            <div className={`
                form-group group
                ${props.className}
                ${form.touched[field.name] && form.errors[field.name] ? 'has-error': ''}
            `}>
                <div className="col-12">
                    {props.label ? <label className="control-label" htmlFor={props.id}>
                        {props.label}
                    </label> : null }
                </div>
                <div className="col-12">
                    <textarea
                        {...field} {...props}
                        onInput={(e) => changeTextareaLength(e)}
                    />

                    <span className="remaining">{length}</span>
                    {form.touched[field.name] && form.errors[field.name] ? <small className="text-danger"> {form.errors[field.name]}</small> : ''}
                </div>
            </div>
        );
}
export default BootstrapTextarea;
