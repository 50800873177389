import React from 'react';
import {connect} from 'react-redux';
import {
    createSetAppointmentSelectedAction,
    createSetUnfoldedGroupAction,
} from "../../actions/Appointments.actions";
import {
    createSetAppointerSelectedResourceAction,
    createClearAppointerSelectedResourceAction,
    createSetAppointerSelectedResourceStateAction
} from "../../actions/Appointer.actions";
import ResourceTypesStateless from "./ResourceTypesStateless";
import {withRouter} from "../../utils/withRouter";

const ResourceTypes =  (props) => {
    const selectAppointment = (appointment) => {
        if ( !props.preview ) {
            props.createSetAppointmentSelectedAction({data: [appointment, props.appointer.permalink.split(['/'])[1]], navigate: props.navigate});
        }
    };

    const selectUnfoldedGroup = (index) => {
        props.createSetUnfoldedGroupAction(index);
    };

    const selectAppointmentResource = (resource) => {
        props.createSetAppointerSelectedResourceAction({resource: {docid: resource.id, uiid: props.urlParams.uuid}, navigate: props.navigate});
        props.createSetAppointerSelectedResourceStateAction(resource);
    };
    // const clearSelectedResource = () => {
    //     props.createClearAppointerSelectedResourceAction();
    // };
    //console.log(props);
    return (
        <ResourceTypesStateless
            resourceTypes={props.resourceTypes}
            appointer={props.appointer}
            appointerSelectedResource={props.appointerSelectedResource}
            history={props.navigate}
            selectUnfoldedGroup={selectUnfoldedGroup}
            selectAppointment={selectAppointment}
            selectAppointmentResource={selectAppointmentResource}
            // clearSelectedResource={clearSelectedResource}
        />
    );
}

const mapStateToProps = (state) =>({
    appointer: state.appointer,
    resourceTypes: state.resourceTypes,
    appointerSelectedResource: state.appointerSelectedResource,
});

export default withRouter(connect(
    mapStateToProps,
    {
        createSetAppointmentSelectedAction,
        createSetUnfoldedGroupAction,
        createSetAppointerSelectedResourceAction,
        createClearAppointerSelectedResourceAction,
        createSetAppointerSelectedResourceStateAction
    }
)(ResourceTypes));
