import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { Form } from "react-bootstrap";
import {GoogleMap, InfoWindow, LoadScript, Marker} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY, GOOGLE_MAPS_DEFAULT_CENTER} from "../../common/config/general";
import AppointerItem from '../../common/components/AppointerMapItem/appointerItem';
import pin from "../../common/images/locationPin.svg";

const EXTRA_TRANSLATIONS = defineMessages({
    specialityPlaceholder: {
        id: 'locationMap.speciality',
        defaultMessage: 'medical speciality',
    },
    areaPlaceholder: {
        id: 'locationMap.area',
        defaultMessage: 'select area',
    },
});
class LocationMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedAppointerMarker: null,
            showInfoBox: false,
        }
    }

    showInfoBox = (appointer) => {
        this.setState({
            selectedAppointerMarker: appointer,
            showInfoBox: true,
        })
    };

    closeInfoBox = () => {
        this.setState({
            selectedAppointerMarker: null,
            showInfoBox: false,
        })
    };

    renderAppointers() {
        return this.props.appointers.map( (appointer, index) => (
            <Marker
                key={index}
                position={ appointer.coordinates }
                icon={pin}
                onClick={(e) => {
                    this.showInfoBox(appointer);
                }}
            />
        ))
    }

    render() {
        return (
            <div className="modalLocation pageLocationSearch">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-6 mb-1">
                            <span className="modal-title"><FormattedMessage id="locationMap.title" defaultMessage="Find your doctor near you on the map"/></span>
                        </div>
                        <div className="col-12 col-md-6 mb-1">
                            <Form>
                                <div className="row">
                                    <div className="col">
                                        <Form.Control as="select" className="selectMedicalSpeciality">
                                            <option>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.specialityPlaceholder)}</option>
                                            <option>2</option>
                                            <option>3</option>
                                            <option>4</option>
                                            <option>5</option>
                                        </Form.Control>
                                    </div>
                                    <div className="col">
                                        <Form.Control as="select" className="selectArea" placeholder="area">
                                            <option>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.areaPlaceholder)}</option>
                                            <option value={5}>Area: 5km</option>
                                            <option value={10}>Area: 10km</option>
                                            <option value={15}>Area: 15km</option>
                                            <option value={20}>Area: 20km</option>
                                        </Form.Control>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="row">
                        <div className="map">
                            <LoadScript
                                id="script-loader"
                                googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                            >
                                <GoogleMap
                                    id='example-map'
                                    zoom={10}
                                    center={GOOGLE_MAPS_DEFAULT_CENTER}
                                >
                                    <Marker
                                        position={{lat: 48.2082, lng: 16.3738}}
                                    />
                                    { this.renderAppointers() }
                                    {
                                        this.state.showInfoBox ?
                                            <InfoWindow
                                                onLoad={infoWindow => {
                                                    //console.log('infoWindow: ', infoWindow)
                                                }}
                                                position={this.state.selectedAppointerMarker.coordinates}
                                                onCloseClick = {this.closeInfoBox}
                                            >
                                                <AppointerItem appointer={this.state.selectedAppointerMarker} />
                                            </InfoWindow> :
                                            null
                                    }
                                </GoogleMap>
                            </LoadScript>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

const mapStateToProps = (state) => ({
    appointers: state.appointers,
});
export default injectIntl(connect(mapStateToProps, {})(LocationMap));
