import React, {Component} from 'react';

class BootstrapSelectbox extends Component {
  state={selectClass: 'placeholder', value: this.props.value};
  changeSelect = (e) => {
    if(e.target.value === '') {
      this.setState({selectClass: 'placeholder'});
    } else {
      this.setState({selectClass: ''});
    }

    this.setState({value: e.target.value})
  };

  render() {
    const opts = {};
    if (this.props.defaultValue !== undefined) {
      opts.value = this.props.defaultValue;
    }
    return (
        <div className={`
                form-group group
                ${this.props.className}
                ${this.props.meta.touched && this.props.meta.error ? 'has-error': ''}
            `}>
          {this.props.label ? <label className="control-label" htmlFor={this.props.input.id}>
            {this.props.label}
          </label> : null }
          <select {...this.props.input} {...opts} placeholder={this.props.placeholder} className={this.state.selectClass} onChange={(e) => this.changeSelect(e)} value={this.state.value}>
            {this.props.values.map((obj) => <option key={obj.reactKey || obj.key} value={obj.key} >{obj.value}</option>)}
          </select>
          {this.props.meta.touched && this.props.meta.error ? <small className="text-danger"> {this.props.meta.error}</small> : ''}
          {this.props.meta.warning? <small className="text-warning">{this.props.meta.warning}</small> : ''}
        </div>
    );
  }
}
export default BootstrapSelectbox;