import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import Calendar from 'react-calendar'
import moment from "moment";
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointerAppointmentResourcesAction,
    createGetAppointerDetailsAction,
    createClearAppointerResourcesAction,
} from "../../common/actions/Appointer.actions";
import {
    createGetAppointmentFreeSlotsAction,
    createGetFreeSlotsDailyIntervalsAction, createSetAppointmentsSelectedReceivedAction,
} from "../../common/actions/Appointments.actions";
import {
    createSaveApplicationLocaleAction
} from "../../common/actions/Application.actions";
import { ComponentSpinner } from "../../common/components/Spinner/ComponentSpinner";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import FreeSlotsContainer from "../../common/components/FreeSlots/FreeSlotsContainer";
import {withRouter} from "../../common/utils/withRouter";

class AppointmentFreeSlots extends Component {

    constructor(props) {
        super(props);

        moment.locale(this.props.DEFAULT_LANGUAGE);
    }

    componentDidMount() {
        if (Object.keys(this.props.appointer).length === 0 && this.props.appointer.constructor === Object) {
            this.props.createClearAppointerResourcesAction();
            this.props.createGetAppointerDetailsAction(this.props.urlParams.uuid);
            const currentDate = moment().format('YYYY-MM-DD');
            setTimeout(() => {
                // this.props.createSaveApplicationLocaleAction(DEFAULT_LANGUAGE);
                this.props.createGetAppointmentFreeSlotsAction({date: currentDate, rta_id: this.props.urlParams.apointmentId});
                const doctors = this.props.appointer.appointer.resource_type_doctors;
                doctors.map((doctor) => {
                    const appointments = doctor.resource_type_appointments.filter((item) => parseInt(item.id) === parseInt(this.props.urlParams.apointmentId))
                    if(appointments.length) {
                        this.props.createSetAppointmentsSelectedReceivedAction(appointments[0])
                    }
                    return true;
                });
            }, 1500)
        } else {
            const currentDate = moment().format('YYYY-MM-DD');
            this.props.createGetAppointmentFreeSlotsAction({date: currentDate, rta_id: this.props.urlParams.apointmentId});
            const doctors = this.props.appointer.appointer.resource_type_doctors;
            doctors.map((doctor) => {
                const appointments = doctor.resource_type_appointments.filter((item) => parseInt(item.id) === parseInt(this.props.urlParams.apointmentId))
                if(appointments.length) {
                    this.props.createSetAppointmentsSelectedReceivedAction(appointments[0])
                }
                return true;
            });
        }
    }

    renderCalendarDay = (dayRenderInfo) => {
        const arrEvents = this.props.freeSlots.filter((freeSlot) => moment(dayRenderInfo.date).format('YYYY-MM-DD') === freeSlot.date);
        const currentDate = moment().startOf('day');
        let classes = '';
        classes += moment(dayRenderInfo.date).isBefore(currentDate) || !arrEvents.length || !arrEvents[0].intervals.length ? ' disable' : '';
        classes += moment(dayRenderInfo.date).format('YYYY-MM-DD') === currentDate.format('YYYY-MM-DD') ? ' today ' : '';
        return classes;
    };

    disableDays = (day) => {
        const arrEvents = this.props.freeSlots.filter((freeSlot) => moment(day.date).format('YYYY-MM-DD') === freeSlot.date);
        return !arrEvents.length || !arrEvents[0].intervals.length;
    };

    customDayClick = (date) => {
        const arrEvents = this.props.freeSlots.filter((freeSlot) => moment(date).format('YYYY-MM-DD') === freeSlot.date);
        const currentDate = moment();
        if ( moment(date).isSameOrAfter(currentDate) && arrEvents.length && arrEvents[0].intervals.length) {
            return this.props.createGetFreeSlotsDailyIntervalsAction({date: moment(date).format('YYYY-MM-DD'), value: 0, rta_id: this.props.urlParams.apointmentId});
        }
        return false;
    };

    updateMonth = (value) => {
        const newDate = moment(this.props.freeSlotsSelectedDate);
        newDate.startOf('day').add(value, 'months');
        if(newDate.isSameOrAfter(moment())) {
            this.props.createGetFreeSlotsDailyIntervalsAction({
                date: newDate.format('YYYY-MM-DD'),
                value,
                rta_id: this.props.urlParams.apointmentId
            });
        } else {
            this.props.createGetFreeSlotsDailyIntervalsAction({
                date: moment().format('YYYY-MM-DD'),
                value,
                rta_id: this.props.urlParams.apointmentId
            });
        }
    };

    goBack = () => {

        this.props.navigate(`/${this.props.isEmbedded ? 'embedded/' : ''}appointer/${this.props.urlParams.uuid}/${this.props.selectedAppointment?.rtd_id}`)
    }

    render() {
        // let embeddedAppointerUrl  = '/embedded/';
        // embeddedAppointerUrl += this.props.appointer && this.props.appointer.permalink ? this.props.appointer.permalink.split('/')[1] : '';
        // const url = this.props.application.isEmbedded  ? embeddedAppointerUrl : this.props.appointer.permalink;

        // console.log(url);
        // console.log(this.props.appointerLoading);

        moment.locale(this.props.DEFAULT_LANGUAGE);

        return (
            !this.props.appointerLoading ?
                <div className="appointer appointersList row">
                    <AppointerDetails appointer={this.props.appointer} isEmbedded={this.props.isEmbedded}/>
                    <Fragment>
                        <div className="col-10 col-md-12 col-lg-10 offset-1 offset-md-0 offset-lg-1 p-0 row">
                            <div className="col-12 p-3 backButtonContainer">
                                <div className="backButtonAppointers" onClick={()=> {
                                    setTimeout(() => this.goBack(), 500);
                                    }}>
                                </div>
                                <div className="text-center">
                                    <h3>
                                        <FormattedMessage id="appointersListItem.selectDateAndTime" defaultMessage="Select date and time"/>
                                    </h3>
                                </div>
                            </div>
                            {
                                !this.props.appointmentLoading ?
                                    <div className="col p-3">
                                        <div className="row">
                                            <div className="col-12 col-md-6 p-3 freeSlotsCalendarContainer">
                                                <div className="freeSlotsCalendar">
                                                    <div className="calendarHeader col-12">
                                                        <span className="prevDate text-left" onClick={() => this.updateMonth(-1)}>
                                                            &#8249;
                                                        </span>
                                                        <span className="freeSloteDayString text-center" tabIndex="0">
                                                            {moment(this.props.freeSlotsSelectedDate).format('MMMM YYYY')}
                                                        </span>
                                                        <span className="nextDate text-end" onClick={() => this.updateMonth(1)}>
                                                          &#8250;
                                                        </span>
                                                    </div>
                                                    <Calendar
                                                        locale={this.props.application.locale}
                                                        defaultView="month"
                                                        tileClassName={ this.renderCalendarDay }
                                                        onClickDay={this.customDayClick}
                                                        defaultValue={new Date(moment(this.props.freeSlotsSelectedDate).format("DD-MM-YYYY"))}
                                                        showNavigation={false}
                                                        tileDisabled={this.disableDays}
                                                        activeStartDate={moment(this.props.freeSlotsSelectedDate).startOf("month").toDate()}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 col-md-6 p-3">
                                                <FreeSlotsContainer uuid={this.props.urlParams.uuid} rta_id={this.props.urlParams.apointmentId} />
                                            </div>
                                        </div>
                                    </div>
                                : <ComponentSpinner type={'white'} />
                            }
                        </div>
                    </Fragment>
                </div>
            : null
        );
    }

}

const mapStateToProps = state => ({
    application: state.application,
    appointer: state.appointer,
    appointerAppointments: state.appointerAppointments,
    appointerLoading: state.appointerLoading,
    freeSlots: state.freeSlots,
    freeSlotsLoading: state.freeSlotsLoading,
    freeSlotsSelectedDate: state.freeSlotsSelectedDate,
    appointmentLoading: state.appointmentLoading,
    isEmbedded: state.application.isEmbedded,
    selectedAppointment: state.selectedAppointment,
});
export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointerAppointmentResourcesAction,
        createGetAppointmentFreeSlotsAction,
        createGetFreeSlotsDailyIntervalsAction,
        createGetAppointerDetailsAction,
        createClearAppointerResourcesAction,
        createSaveApplicationLocaleAction,
        createSetAppointmentsSelectedReceivedAction
    })(AppointmentFreeSlots))));
