
export const HOME_ROUTE = '/';
export const LOCATION_MAP_SEARCH = '/map-search';
export const APPOINTER_ROUTE = '/appointer/:uuid';
export const APPOINTER_DOCTOR_ROUTE = '/appointer/:uuid/:docid';
export const PREVIEW_APPOINTER_ROUTE = '/preview/:uuid';
export const EMBED_APPOINTER_ROUTE = '/embedded/:uuid';
export const NOT_FOUND_ROUTE = '/not-found';

export const APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE = '/appointments/:uuid/:apointmentId';
export const APPOINTMENT_CONFIRMATION = '/appointment/:uuid/:apointmentId/:date/:st';
export const APPOINTMENT_CODE_VERIFICATION = '/appointment/verify/:uuid';
export const APPOINTMENT_CONFIRMED = '/appointment/confirmed/:uuid';
export const APPOINTMENT_DETAILS_PAGE = '/appointment/details/:uuid';

export const EMBED_APPOINTER_APPOINTMENT_FREE_SLOTS_ROUTE = '/embedded/appointments/:uuid/:apointmentId';
export const EMBED_APPOINTMENT_CONFIRMATION = '/embedded/appointment/:uuid/:apointmentId/:date/:st';
export const EMBED_APPOINTMENT_CODE_VERIFICATION = '/embedded/appointment/verify/:uuid';
export const EMBED_APPOINTMENT_CONFIRMED = '/embedded/appointment/confirmed/:uuid';
export const EMBED_APPOINTMENT_DETAILS_PAGE = '/embedded/appointment/details/:uuid';

export const RESET_PASSWORD = '/pr/:hash';
export const RESET_EMAIL = '/reset-email/:hash';
export const TERMS_AND_CONDITIONS = '/conditions';
export const GDPR = '/gdpr';
export const IMPRESSUM = '/impressum';
export const CONTACT = '/contact';