import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointerAppointmentResourcesAction,
    createGetAppointerDetailsAction,
    createClearAppointerResourcesAction,
} from "../../common/actions/Appointer.actions";
import {
    createGetAppointmentFreeSlotsAction,
    createGetFreeSlotsDailyIntervalsAction,
    createGetAppointmentSubmitAction,
    createSetFreeSlotsSeletedIntervalAction
} from "../../common/actions/Appointments.actions";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import { AppointmentConfirmationForm } from "./appointmentConfirmationForm";
import moment from "moment";
import {withRouter} from "../../common/utils/withRouter";

class AppointmentConfirmation extends Component {

    componentDidMount() {
        if (Object.keys(this.props.appointer).length === 0 && this.props.appointer.constructor === Object) {
            this.props.location.pathname.replace('/');
            this.props.createClearAppointerResourcesAction();
            this.props.createGetAppointerDetailsAction(this.props.urlParams.uuid);
            const currentDate =  moment(this.props.urlParams.date).format('YYYY-MM-DD');

            setTimeout(() => {
                // this.props.createSaveApplicationLocaleAction(DEFAULT_LANGUAGE);
                this.props.createGetAppointmentFreeSlotsAction({date: currentDate, rta_id: this.props.urlParams.apointmentId, confirmation: true});
            }, 1500)

            setTimeout(() => {
                this.props.createGetFreeSlotsDailyIntervalsAction({date: currentDate, value: 0, rta_id: this.props.urlParams.apointmentId});
            }, 1500)

            setTimeout(() => {
                if(this.props.freeSlotsIntervals) {
                    const slot = this.props.freeSlotsIntervals.intervals.filter(interval => interval.st === this.props.urlParams.st)
                    this.props.createSetFreeSlotsSeletedIntervalAction(slot);
                } else {
                    this.props.history.replace('/');
                }
            }, 2000)
        }
    }

    submitConfirmation = (dataObject) => {
        const keyArr = Object.keys(dataObject);
        const customFields = {};

        const filterKey = keyArr.filter(key => {
            const cf = key.slice(0, 2);
            return cf === 'cf';
        });

        filterKey.map(key => customFields[key] = dataObject[key]);

        const newDataObj = {
            email: dataObject.email,
            firstname: dataObject.firstname,
            gender: dataObject.gender,
            lastname: dataObject.lastname,
            tel: dataObject.tel,
            custom_fields: customFields
        };
        this.props.createGetAppointmentSubmitAction({data: newDataObj, navigate: this.props.navigate});
    };
    goBack = () => {
        this.props.navigate(`/${this.props.isEmbedded ? 'embedded/' : ''}appointments/${this.props.urlParams.uuid}/${this.props.urlParams.apointmentId}`)
    }

    render() {
        return (
            !this.props.appointerLoading ?
                <div className="appointer appointersList row">
                    <AppointerDetails appointer={this.props.appointer} isEmbedded={this.props.isEmbedded}/>

                    <Fragment>
                        <div className="col-10 offset-1 p-0 row">
                            <div className="col-12 p-3 backButtonContainer">
                                <div className="backButtonAppointers" onClick={()=> {
                                    setTimeout(() => this.goBack(), 500);
                                }}>
                                </div>
                                <div className="text-center">
                                    <h3>
                                        <FormattedMessage id="appointersListItem.getVerificationCode" defaultMessage="get verification code"/>
                                    </h3>
                                </div>
                            </div>
                            <div className="col-12">
                                <AppointmentConfirmationForm
                                    onSubmitForm={this.submitConfirmation}
                                    application={this.props.application}
                                    appointer={this.props.appointer}
                                />
                            </div>
                        </div>
                    </Fragment>
                </div>
            : null
        );
    }

}

const mapStateToProps = state => ({
    application: state.application,
    appointer: state.appointer,
    appointerAppointments: state.appointerAppointments,
    appointerLoading: state.appointerLoading,
    freeSlots: state.freeSlots,
    freeSlotsLoading: state.freeSlotsLoading,
    freeSlotsSelectedDate: state.freeSlotsSelectedDate,
    freeSlotsIntervals: state.freeSlotsIntervals,
    appointmentLoading: state.appointmentLoading,
    isEmbedded: state.application.isEmbedded,
});

export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointerAppointmentResourcesAction,
        createGetAppointmentFreeSlotsAction,
        createGetFreeSlotsDailyIntervalsAction,
        createGetAppointmentSubmitAction,

        createClearAppointerResourcesAction,
        createGetAppointerDetailsAction,
        createSetFreeSlotsSeletedIntervalAction
    })(AppointmentConfirmation))));
