export const SUBMIT_PASSWORD_RESET = 'SUBMIT_PASSWORD_RESET';
export const SUBMIT_PASSWORD_RESET_RECEIVED = 'SUBMIT_PASSWORD_RESET_RECEIVED';
export const PASSWORD_RESET_LOADING = 'PASSWORD_RESET_LOADING';

export const createPassordResetAction = (payload) => ({
    type: SUBMIT_PASSWORD_RESET,
    payload,
});

export const createPasswordResetReceivedAction = (payload) => ({
    type: SUBMIT_PASSWORD_RESET_RECEIVED,
    payload,
});

export const createSetPasswordResetLoadingAction = (payload) => ({
    type: PASSWORD_RESET_LOADING,
    payload,
});