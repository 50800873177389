export const SHOW_ALERT = 'SHOW_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const showAlert= (payload) => ({
    type: SHOW_ALERT,
    payload
});

export const removeAlert = () => ({
    type: REMOVE_ALERT
});