import React, {Component} from 'react';
import impressumImg from '../../common/images/wko_e-commerce_big.png';

export default class Impressum extends Component {

    render() {
        return (<div className="staticPages container grey">
            <h1 className="text-center">Impressum</h1>
            <div className="row pt-3">
                <div className="col-6 text-right">Eigentümer:</div><div className="col-6 text-left">Ing Tudor-Octav Ples | Simion Hurghis, MSc</div>
            </div>
            <div className="row pt-3">
                <div className="col-6 text-right">Unternehmen:</div><div className="col-6 text-left">TOP-IT e.U. | Simion Hurghis eU</div>
            </div>
            <div className="row pt-3">
                <div className="col-6 text-right">Firmenbuchnr.:</div><div className="col-6 text-left">FN 354923x</div>
            </div>
            <div className="row pt-3">
                <div className="col-6 text-right">Handelsgericht:</div><div className="col-6 text-left">Handelsgericht Wien</div>
            </div>
            <div className="row pt-3">
                <div className="col-6 text-right">E-mail:</div><div className="col-6 text-left">office[a]sanasoft[.]at</div>
            </div>
            <div className="row pt-3">
                <div className="col-12 text-center">
                    Offenlegung gemäß §§ 25 Mediengesetz und Information gemäß § 5 E-Commerce-Gesetz:
                </div>
                <div className="col-12 text-center pt-3">
                    <img src={impressumImg} alt="Offenlegung gemäß §§ 25 Mediengesetz und Information gemäß § 5 E-Commerce-Gesetz" />
                </div>
            </div>
        </div>);
    }

}