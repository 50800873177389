import React from 'react';
import {RESOURCE_TYPES, RESOURCE_TYPES_ICONS} from "../../config/general";
import rtAppoitment from "../../images/one_consultation.svg";

const ResourceTypeItem = (props) => {
    const iconImg = RESOURCE_TYPES_ICONS[props.resource.type] || rtAppoitment;
    const description = props.resource.type === RESOURCE_TYPES.speciality ?
        props.resource.description || '' :
        props.resource.info || '';
    return (
        <div className={props.className} onClick={props.onSelectUnfold}>
            <div className="resourceTypeItem">
                <div className="text-center imageContainer">
                    <img src={props.resource.profile_image || iconImg} alt={props.resource.title}/>
                </div>
                <h3 className="text-center"> {props.resource.title} </h3>
                <hr/>
                {
                    description !== '' ?
                        <div className="text-center smallText pt-1">
                            {description}
                        </div> :
                    null
                }
            </div>
        </div>
    );
};

export default ResourceTypeItem;
