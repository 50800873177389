import React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

export const  NotFound = () => (
    <div className="notFound">
        <h3><FormattedMessage id="notFound.title" defaultMessage="Not Found 404"/></h3>
        <a className="text-white" href="/"><FormattedMessage id="notFound.link" defaultMessage="Please go back to home"/>  </a>
    </div>
);