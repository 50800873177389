import en from '../translations/lang/en.json';
import de from '../translations/lang/de.json';
import fr from '../translations/lang/fr.json';
import it from '../translations/lang/it.json';
import ro from '../translations/lang/ro.json';

import {
  SET_APPLICATION_LANGUAGE,
  SET_APPLICATION_LOADING, SET_EMBEDDED_APPLICATION,
} from '../actions/Application.actions';

const initialState = {
  locale: 'de',
  messages: {},
  loading: true,
  isEmbedded: false,
};
const languages = { en, de, fr, it, ro };

const ApplicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPLICATION_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
        messages: typeof action.payload === 'string' ? languages[action.payload] : undefined,
      };

    case SET_EMBEDDED_APPLICATION:
      return {
        ...state,
        isEmbedded: action.payload,
      };

    case SET_APPLICATION_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default ApplicationReducer;
