import axios from 'axios';
import { buildPath } from './Base.api';

export function* getAppointmentFreeSlots({a_id, rta_id, start_date}) {
    const url = buildPath(`appointments/free-slots-intervals/${a_id}/${rta_id}/${start_date}`);
    return yield axios.get(url);
}

export function* submitAppointment(objData) {
    const url = buildPath('appointments');
    return yield axios.post(url, objData);
}

export function* getAppointmentDetails(uuid) {
    const url = buildPath(`appointments/${uuid}`);
    return yield axios.get(url);
}

export function* verifyCode(data) {
    const url = buildPath('appointments/verify-code');
    return yield axios.put(url, data);
}

export function* cancelBooking(uuid, info) {
    const url = buildPath(`appointments/${uuid}`);
    return yield axios.delete(url, { data: { info }});
}

export function* resendSms(uuid, tel) {
    const url = buildPath('appointments/resend-booking-code');
    return yield axios.put(url, { uuid, tel});
}