import {
  GET_APPOINTER_DOCTOR_RESOURCES_RECEIVED,
  CLEAR_APPOINTER_RESOURCES,
} from "../actions/Appointer.actions";

const initialState = [];

const AppointerDoctorsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTER_DOCTOR_RESOURCES_RECEIVED:
      return [
        ...action.payload,
      ];
    case CLEAR_APPOINTER_RESOURCES:
      return initialState;
    default:
      return state;
  }
};

export default AppointerDoctorsReducer;
