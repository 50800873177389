export const GET_APPOINTERS = 'GET_APPOINTERS';
export const GET_APPOINTERS_RECEIVED = 'GET_APPOINTERS_RECEIVED';
export const GET_FILTERED_APPOINTERS = 'GET_FILTERED_APPOINTERS';
export const SET_APPOINTERS_LIST_LOADING = 'SET_APPOINTERS_LIST_LOADING';
export const GET_APPOINTERS_SPECIALTIES = 'GET_APPOINTERS_SPECIALTIES';
export const SET_APPOINTERS_SPECIALTIES_RECEIVED = 'SET_APPOINTERS_SPECIALTIES_RECEIVED';

export const createGetAppointersAction = () => ({
    type: GET_APPOINTERS,
});

export const createGetAppointersReceivedAction = (payload) => ({
    type: GET_APPOINTERS_RECEIVED,
    payload,
});

export const createGetFilteredAppointersAction = (payload) => ({
    type: GET_FILTERED_APPOINTERS,
    payload,
});

export const createSetAppointersListLoadingAction = (payload) => ({
    type: SET_APPOINTERS_LIST_LOADING,
    payload,
});

export const createGetAppointersSpecialtiesAction = () => ({
    type: GET_APPOINTERS_SPECIALTIES,
});

export const createSetAppointersSpecialtiesReceivedAction = (payload) => ({
    type: SET_APPOINTERS_SPECIALTIES_RECEIVED,
    payload,
});