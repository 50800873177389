import React, {Component} from 'react';

export default class Gdpr extends Component {

    render() {
        return (<div className="staticPages">
            <h1 className="text-center">Datenschutzerklärung</h1>

            <h3>1. Grundlegendes</h3>
            <p>
                Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und
                den Zweck der Erhebung und Verwendung personenbezogener Daten durch den
                Websitebetreiber [Ihre Kontaktdaten einfügen] informieren.
            </p>
            <p>
                Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre
                personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Vorschriften. Da
                durch neue Technologien und die ständige Weiterentwicklung dieser Webseite Änderungen
                an dieser Datenschutzerklärung vorgenommen werden können, empfehlen wir Ihnen sich die
                Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
            </p>
            <p>
                Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
                finden Sie in Art. 4 DSGVO.
            </p>

            <h3>SSL-Verschlüsselung </h3>

            <p>
                Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher
                Inhalte, die Sie an uns als Seitenbetreiber senden, eine SSL-Verschlüsselung. Eine
                verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von
                “http://” auf “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die
                SSL-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von
                Dritten mitgelesen werden.
            </p>

            <h3>
                2. Zugriffsdaten
            </h3>

            <p>
                Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten
                Interesses (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern
                diese als „Server-Logfiles“ auf dem Server der Website ab. Folgende Daten werden so
                protokolliert:
            </p>
            <ul>
                <li>
                    Besuchte Website
                </li>
                <li>
                    Uhrzeit zum Zeitpunkt des Zugriffes
                </li>
                <li>
                    Menge der gesendeten Daten in Byte
                </li>
                <li>
                    Quelle/Verweis, von welchem Sie auf die Seite gelangten
                </li>
                <li>
                    Verwendeter Browser
                </li>
                <li>
                    Verwendetes Betriebssystem
                </li>
                <li>
                    Verwendete IP-Adresse
                </li>
            </ul>
            <p>
                Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die
                Speicherung der Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären
                zu können. Müssen Daten aus Beweisgründen aufgehoben werden, sind sie solange von der
                Löschung ausgenommen bis der Vorfall endgültig geklärt ist.
            </p>

            <h3>3. Cookies </h3>
            <p>
                Diese Website verwendet Cookies zur pseudonymisierten Reichweitenmessung, die
                entweder von unserem Server oder dem Server Dritter an den Browser des Nutzers
                übertragen werden. Bei Cookies handelt es sich um kleine Dateien, welche auf Ihrem
                Endgerät gespeichert werden. Ihr Browser greift auf diese Dateien zu. Durch den Einsatz
                von Cookies erhöht sich die Benutzerfreundlichkeit und Sicherheit dieser Website.
            </p>
            <p>
                Falls Sie nicht möchten, dass Cookies zur Reichweitenmessung auf Ihrem Endgerät
                gespeichert werden, können Sie dem Einsatz dieser Dateien hier widersprechen:
            </p>
            <ul>
                <li>
                    Cookie-Deaktivierungsseite der Netzwerkwerbeinitiative:<br />
                    <a href="http://optout.networkadvertising.org/?c=1#!/" target="_blank" rel="noopener noreferrer">http://optout.networkadvertising.org/?c=1#!/</a>
                </li>
                <li>
                    Cookie-Deaktivierungsseite der US-amerikanischen Website:<br />
                    <a href="http://optout.aboutads.info/?c=2#!/" target="_blank" rel="noopener noreferrer">http://optout.aboutads.info/?c=2#!/</a>
                </li>
                <li>
                    Cookie-Deaktivierungsseite der europäischen Website:<br />
                    <a href="http://optout.networkadvertising.org/?c=1#!/" target="_blank" rel="noopener noreferrer">http://optout.networkadvertising.org/?c=1#!/</a>
                </li>
            </ul>
            <p>
                Gängige Browser bieten die Einstellungsoption, Cookies nicht zuzulassen. Hinweis: Es ist
                nicht gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen
                zugreifen können, wenn Sie entsprechende Einstellungen vornehmen.
            </p>
            <p>
                Erfassung und Verarbeitung personenbezogener Daten
            </p>
            <p>
                Der Websitebetreiber erhebt, nutzt und gibt Ihre personenbezogenen Daten nur dann weiter,
                wenn dies im gesetzlichen Rahmen erlaubt ist oder Sie in die Datenerhebung einwilligen.
                Als personenbezogene Daten gelten sämtliche Informationen, welche dazu dienen, Ihre
                Person zu bestimmen und welche zu Ihnen zurückverfolgt werden können – also
                beispielsweise Ihr Name, Ihre E-Mail-Adresse und Telefonnummer.
            </p>
            <p>
                Diese Website können Sie auch besuchen, ohne Angaben zu Ihrer Person zu machen. Zur
                Verbesserung unseres Online-Angebotes speichern wir jedoch (ohne Personenbezug) Ihre
                Zugriffsdaten auf diese Website. Zu diesen Zugriffsdaten gehören z. B. die von Ihnen
                angeforderte Datei oder der Name Ihres Internet-Providers. Durch die Anonymisierung der
                Daten sind Rückschlüsse auf Ihre Person nicht möglich.
            </p>

            <h3>4. Umgang mit Kontaktdaten </h3>
            <p>
                Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten
                Verbindung auf, werden Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und
                Beantwortung Ihrer Anfrage zurückgegriffen werden kann. Ohne Ihre Einwilligung werden
                diese Daten nicht an Dritte weitergegeben.
            </p>

            <h3>5. Google Analytics </h3>
            <p>
                Diese Website nutzt aufgrund unserer berechtigten Interessen zur Optimierung und Analyse
                unseres Online-Angebots im Sinne des Art. 6 Abs. 1 lit. f. DSGVO den Dienst „Google
                Analytics“, welcher von der Google Inc. (1600 Amphitheatre Parkway Mountain View, CA
                94043, USA) angeboten wird. Der Dienst (Google Analytics) verwendet „Cookies“ –
                Textdateien, welche auf Ihrem Endgerät gespeichert werden. Die durch die Cookies
                gesammelten Informationen werden im Regelfall an einen Google-Server in den USA
                gesandt und dort gespeichert.
            </p>
            <p>
                Google LLC hält das europäische Datenschutzrecht ein und ist unter dem Privacy-ShieldAbkommen zertifiziert: <br/>
                <a href="https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&status=Active</a>
            </p>
            <p>
                Auf dieser Website greift die IP-Anonymisierung. Die IP-Adresse der Nutzer wird innerhalb
                der Mitgliedsstaaten der EU und des Europäischen Wirtschaftsraum und in den anderen
                Vertragsstaaten des Abkommens gekürzt. Nur in Einzelfällen wird die IP-Adresse zunächst
                ungekürzt in die USA an einen Server von Google übertragen und dort gekürzt. Durch diese
                Kürzung entfällt der Personenbezug Ihrer IP-Adresse. Die vom Browser übermittelte IPAdresse des Nutzers wird nicht mit anderen von Google gespeicherten Daten kombiniert.
            </p>
            <p>
                Im Rahmen der Vereinbarung zur Auftragsdatenvereinbarung, welche wir als
                Websitebetreiber mit der Google Inc. geschlossen haben, erstellt diese mithilfe der
                gesammelten Informationen eine Auswertung der Websitenutzung und der Websiteaktivität
                und erbringt mit der Internetnutzung verbundene Dienstleistungen.
            </p>
            <p>
                Die von Google in unserem Auftrag erhobenen Daten werden genutzt, um die Nutzung
                unseres Online-Angebots durch die einzelnen Nutzer auswerten zu können, z. B. um
                Reports über die Aktivität auf der Website zu erstellen, um unser Online-Angebot zu
                verbessern.
            </p>
            <p>
                Sie haben die Möglichkeit, die Speicherung der Cookies auf Ihrem Gerät zu verhindern,
                indem Sie in Ihrem Browser entsprechende Einstellungen vornehmen. Es ist nicht
                gewährleistet, dass Sie auf alle Funktionen dieser Website ohne Einschränkungen zugreifen
                können, wenn Ihr Browser keine Cookies zulässt.
            </p>
            <p>
                Weiterhin können Sie durch ein Browser-Plugin verhindern, dass die durch Cookies
                gesammelten Informationen (inklusive Ihrer IP-Adresse) an die Google Inc. gesendet und
                von der Google Inc. genutzt werden. Folgender Link führt Sie zu dem entsprechenden
                Plugin: <a href="https://tools.google.com/dlpage/gaoptout?hl=de" target="_blank" rel="noopener noreferrer">https://tools.google.com/dlpage/gaoptout?hl=de</a>
            </p>
            <p>
                Alternativ verhindern Sie mit einem Klick auf diesen Link (WICHTIG: Opt-Out-Link einfügen),
                dass Google Analytics innerhalb dieser Website Daten über Sie erfasst. Mit dem Klick auf
                obigen Link laden Sie ein „Opt-Out-Cookie“ herunter. Ihr Browser muss die Speicherung von
                Cookies also hierzu grundsätzlich erlauben. Löschen Sie Ihre Cookies regelmäßig, ist ein
                erneuter Klick auf den Link bei jedem Besuch dieser Website vonnöten.
            </p>
            <p>
                Hier finden Sie weitere Informationen zur Datennutzung durch die Google Inc.:
            </p>
            <ul>
                <li><a href="https://policies.google.com/privacy/partners?hl=de" target="_blank" rel="noopener noreferrer"> https://policies.google.com/privacy/partners?hl=de</a> (Daten, die von GooglePartnern erhoben werden)</li>
                <li><a href="https://adssettings.google.de/authenticated" target="_blank" rel="noopener noreferrer"> https://adssettings.google.de/authenticated </a> (Einstellungen über Werbung, die
                    Ihnen angezeigt wird)</li>
                <li><a href="https://policies.google.com/technologies/ads?hl=de" target="_blank" rel="noopener noreferrer"> https://policies.google.com/technologies/ads?hl=de </a> (Verwendung von Cookies in
                    Anzeigen)</li>
            </ul>

            <h3>6. Google reCAPTCHA </h3>
            <p>
                Wir nutzen “Google reCAPTCHA” (im Folgenden “reCAPTCHA”) auf unseren Websites.
                Anbieter ist die Google Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA
                (“Google”).
            </p>
            <p>
                Mit reCAPTCHA soll überprüft werden, ob die Dateneingabe auf unseren Websites (z.B. in
                einem Kontaktformular) durch einen Menschen oder durch ein automatisiertes Programm
                erfolgt. Hierzu analysiert reCAPTCHA das Verhalten des Websitebesuchers anhand
                verschiedener Merkmale. Diese Analyse beginnt automatisch, sobald der Websitebesucher
                die Website betritt. Zur Analyse wertet reCAPTCHA verschiedene Informationen aus (z.B.
                IP- Adresse, Verweildauer des Websitebesuchers auf der Website oder vom Nutzer getätigte
                Mausbewegungen). Die bei der Analyse erfassten Daten werden an Google weitergeleitet.
            </p>
            <p>
                Die reCAPTCHA-Analysen laufen vollständig im Hintergrund. Websitebesucher werden nicht
                darauf hingewiesen, dass eine Analyse stattfindet. Die Datenverarbeitung erfolgt auf
                Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes
                Interesse daran, seine Webangebote vor missbräuchlicher automatisierter Ausspähung und
                vor SPAM zu schützen.
            </p>
            <p>
                Weitere Informationen zu Google reCAPTCHA sowie die Datenschutzerklärung von Google
                entnehmen Sie folgenden Links: <a href="https://www.google.de/intl/de/policies/privacy/" target="_blank" rel="noopener noreferrer">https://www.google.de/intl/de/policies/privacy/</a> und
                <a href="https://www.google.com/recaptcha/intro/android.html" target="_blank" rel="noopener noreferrer"> https://www.google.com/recaptcha/intro/android.html</a>.
            </p>

            <h3>7. Nutzung von Social‐Media‐Plugins von Facebook </h3>
            <p>
                Aufgrund unseres berechtigten Interesses an der Analyse, Optimierung und dem Betrieb
                unseres Online-Angebotes (im Sinne des Art. 6 Abs. 1 lit. f. DSGVO), verwendet diese
                Website das Facebook-Social-Plugin, welches von der Facebook Inc. (1 Hacker Way, Menlo
                Park, California 94025, USA) betrieben wird. Erkennbar sind die Einbindungen an dem
                Facebook-Logo bzw. an den Begriffen „Like“, „Gefällt mir“, „Teilen“ in den Farben Facebooks
                (Blau und Weiß). Informationen zu allen Facebook-Plugins finden Sie über den folgenden
                Link: <a href="https://developers.facebook.com/docs/plugins/" target="_blank" rel="noopener noreferrer">https://developers.facebook.com/docs/plugins/</a>
            </p>
            <p>
                Facebook Inc. hält das europäische Datenschutzrecht ein und ist unter dem Privacy-ShieldAbkommen zertifiziert:
                <a href="https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active" target="_blank" rel="noopener noreferrer">https://www.privacyshield.gov/participant?id=a2zt0000000GnywAAC&status=Active</a>
            </p>
            <p>
                Das Plugin stellt eine direkte Verbindung zwischen Ihrem Browser und den FacebookServern her. Der Websitebetreiber hat keinerlei Einfluss auf die Natur und den Umfang der
                Daten, welche das Plugin an die Server der Facebook Inc. übermittelt. Informationen dazu
                finden Sie hier: <a href="https://www.facebook.com/help/186325668085084" target="_blank" rel="noopener noreferrer">https://www.facebook.com/help/186325668085084</a>
            </p>
            <p>
                Das Plugin informiert die Facebook Inc. darüber, dass Sie als Nutzer diese Website besucht
                haben. Es besteht hierbei die Möglichkeit, dass Ihre IP-Adresse gespeichert wird. Sind Sie
                während des Besuchs auf dieser Website in Ihrem Facebook-Konto eingeloggt, werden die
                genannten Informationen mit diesem verknüpft.
            </p>
            <p>
                Nutzen Sie die Funktionen des Plugins – etwa indem Sie einen Beitrag teilen oder „liken“ –,
                werden die entsprechenden Informationen ebenfalls an die Facebook Inc. übermittelt.
                Möchten Sie verhindern, dass die Facebook. Inc. diese Daten mit Ihrem Facebook-Konto
                verknüpft, loggen Sie sich bitte vor dem Besuch dieser Website bei Facebook aus und
                löschen Sie die gespeicherten Cookies. Über Ihr Facebook-Profil können Sie weitere
                Einstellungen zur Datenverarbeitung für Werbezwecke tätigen oder der Nutzung Ihrer Daten
                für Werbezwecke widersprechen. Zu den Einstellungen gelangen Sie hier:
            </p>
            <ul>
                <li>
                    Profileinstellungen bei Facebook:
                    <a href="https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen" target="_blank" rel="noopener noreferrer">https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen</a>
                </li>
                <li>
                    Cookie-Deaktivierungsseite der US-amerikanischen Website:
                    <a href="http://optout.aboutads.info/?c=2#!/" target="_blank" rel="noopener noreferrer">http://optout.aboutads.info/?c=2#!/</a>
                </li>
                <li>
                Cookie-Deaktivierungsseite der europäischen Website:
                    <a href="http://optout.networkadvertising.org/?c=1#!/" target="_blank" rel="noopener noreferrer">http://optout.networkadvertising.org/?c=1#!/</a></li>
            </ul>

            <h3>8. Rechte des Nutzers </h3>
            <p>
                Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten,
                welche personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das
                Recht auf Berichtigung falscher Daten und auf die Verarbeitungseinschränkung oder
                Löschung Ihrer personenbezogenen Daten. Falls zutreffend, können Sie auch Ihr Recht auf
                Datenportabilität geltend machen. Sollten Sie annehmen, dass Ihre Daten unrechtmäßig
                verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen Aufsichtsbehörde
                einreichen.
            </p>

            <h3>
                9. Löschung von Daten
            </h3>
            <p>
                Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B.
                Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von
                uns gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten
                sein und es keine gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung
                nicht durchgeführt werden kann, da die Daten für zulässige gesetzliche Zwecke erforderlich
                sind, erfolgt eine Einschränkung der Datenverarbeitung. In diesem Fall werden die Daten
                gesperrt und nicht für andere Zwecke verarbeitet.
            </p>

            <h3>
                10.Widerspruchsrecht
            </h3>
            <p>
                Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der
                Verarbeitung ihrer personenbezogenen Daten zu jeder Zeit widersprechen.
            </p>
            <p>
                Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person
                gespeicherten personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung,
                Verarbeitung oder Verwendung Ihrer personenbezogenen Daten haben oder erteilte
                Einwilligungen widerrufen möchten, wenden Sie sich bitte an folgende E-Mail-Adresse:
                support[a]sanasoft.eu
            </p>
        </div>);
    }

}