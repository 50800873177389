import {
  CLEAR_APPOINTER_RESOURCES,
} from "../actions/Appointer.actions";
import {SET_FREE_SLOT_SELECTED_DATE} from "../actions/Appointments.actions";

const initialState = null;

const FreeSlotSelectedDateReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_FREE_SLOT_SELECTED_DATE:
      return action.payload;
    case CLEAR_APPOINTER_RESOURCES:
      return initialState;
    default:
      return state;
  }
};

export default FreeSlotSelectedDateReducer;
