import {
  SUBMIT_EMAIL_RESET_RECEIVED,
} from '../actions/EmailReset.actions';

const initialState = null;

const EmailResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_EMAIL_RESET_RECEIVED:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default EmailResetReducer;
