import * as React from 'react';
import {FormattedMessage} from "react-intl";

export const ComponentSpinner = (props) => {
    const type = props.type || 'info';

    return (
        <div className="componentSpinner">
            <div className={`spinner-border text-${type}`} role="status">
                <span className="d-block d-sm-none"><FormattedMessage id="spinner.loading" defaultMessage="Loading..."/></span>
            </div>
        </div>
    );
}

