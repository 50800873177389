import axios from 'axios';
import { getItemFromStorage,  saveItemToStorage } from '../utils/storage';
import {
    takeLatest,
    call,
    select,
    put,
} from 'redux-saga/effects';
import moment from 'moment/moment';
import {
    APPLICATION_INIT,
    createSetApplicationLanguageAction, createSetApplicationLoadingAction,
    SAVE_APPLICATION_LOCALE
} from '../actions/Application.actions';
import * as Authentication from '../api/Authentication.api';
import {DEFAULT_LANGUAGE, STORAGE_LANGUAGE_KEY} from "../config/languages";
import {ALERT_TYPES} from "../config/alertTypes";
import {showAlert} from "../actions/Alert.actions";

function isTokenValid(expirationDate) {
    if (moment().isAfter(moment(expirationDate).format())) {
        return false;
    }
    return true;
}

function* applicationInit() {

    yield put(createSetApplicationLoadingAction(true));
    let accessToken = getItemFromStorage('access_token');
    let expirationDate = getItemFromStorage('expires_in');

    if((!accessToken && !expirationDate) || !isTokenValid(expirationDate)) {
        const response = yield Authentication.getPublicToken();
        expirationDate = response.data.expires_in;
        accessToken = response.data.access_token;

        saveItemToStorage('access_token', accessToken);
        saveItemToStorage('expires_in', expirationDate);

        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }

    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axios.defaults.headers.common['Cache-Control'] = 'no-store, must-revalidate';
    axios.defaults.headers.common.Pragma = 'no-cache';
    axios.defaults.headers.Expires = 0;

    yield put(createSetApplicationLanguageAction(DEFAULT_LANGUAGE));
    yield call(saveItemToStorage, STORAGE_LANGUAGE_KEY, DEFAULT_LANGUAGE);
    moment.locale(DEFAULT_LANGUAGE);
    axios.defaults.headers.common['Accept-Language'] = DEFAULT_LANGUAGE;

    yield put(createSetApplicationLoadingAction(false));

}

function* setApplicationLanguage(action) {
    yield call(saveItemToStorage, STORAGE_LANGUAGE_KEY, action.payload);
    yield put(createSetApplicationLanguageAction(action.payload));
    moment.locale(action.payload);
    axios.defaults.headers.common['Accept-Language'] = action.payload;
}

function* takeADump(err){
    const dataHash = {
        error: err,
        currentState: yield select((state) => state),
    };
    if (!err.response.data || !err.response.data.name) {
        //throw err;
        console.log(dataHash);
    }
}

function* handleError(err, saga, action, ...args) {
    if (err.response && [400, 401, 403, 404, 405, 406, 422, 500].includes(err.response.status)) {
        if (err.response.status === 403 || err.response.status === 401) {
            const response = yield Authentication.getPublicToken();
            const expirationDate = response.data.expires_in;
            const accessToken = response.data.access_token;

            saveItemToStorage('access_token', accessToken);
            saveItemToStorage('expires_in', expirationDate);

            axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
            if(accessToken) {
                yield call(saga, action, ...args);
            }
        } else {
            yield put(showAlert({
                type: ALERT_TYPES.error,
                message: err.response.data.message,
            }));
            if (err.response.status === 404) {
                window.location.href='/';
            }
        }
        yield takeADump(err);
    } else {
        yield takeADump(err);
    }
}

export const genericErrorHandler = (saga, ...args) => (
    function* handleApp(action){
        try {
            yield call(saga, ...args, action);
        } catch (err) {
            yield call(handleError, err, saga, action, ...args);
        }
    }
);


export function* applicationSaga() {
    yield takeLatest(APPLICATION_INIT, genericErrorHandler(applicationInit));
    yield takeLatest(SAVE_APPLICATION_LOCALE, genericErrorHandler(setApplicationLanguage))
}
