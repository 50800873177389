import rtAppoitment from "../images/one_consultation.svg";
import rtDoctor from "../images/one_doctor.svg";
import rtSpeciality from "../images/one_specialty.svg";
import rtAllAppointments from "../images/group_consultations.svg";
import rtAllDoctors from "../images/group_doctors.svg";
import rtAllSpecialties from "../images/group_specialties.svg";
import rtHospital from "../images/hospital.svg";

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const GOOGLE_MAPS_DEFAULT_CENTER = {
    lat: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_COORDINATES_LAT),
    lng: parseFloat(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER_COORDINATES_LNG),
};

export const searchBoxItemsType = {
    'specialty': 0,
    'doctor': 1,
    'clinic': 2,
};

export const searchBoxIcons = {
    0: rtSpeciality,
    1: rtDoctor,
    2: rtHospital,
};
export const RESOURCE_TYPES = {
    'speciality': 0,
    'doctor': 1,
    'appointment': 2,
    'allDoctors': 11,
    'allSpecialties': 10,
    'allAppointments':12,
};

export const RESOURCE_TYPES_ICONS = {
    0: rtSpeciality,
    1: rtDoctor,
    2: rtAppoitment,
    10: rtAllSpecialties,
    11: rtAllDoctors,
    12: rtAllAppointments,
};

export const APPOINTMENT_STATUS = {
    pending: 0,
    confirmed: 1,
};

export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY;