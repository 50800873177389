import React from 'react';
import PhoneInput from "react-phone-number-input";
const BootstrapPhoneInput = ({ field, form, meta, ...props }) => {
    // const [helpers] = useField(field.name);

    return (
        <div className={`
                form-group group
                ${props.className}
            `}>
            {props.label ? <label className="control-label" htmlFor={props.id}>
                {props.label}
            </label> : null }
            <PhoneInput
                {...props}
                {...field}
                international
                value={field.value}
                defaultCountry={props.country}
                onChange={(value) => {
                    form.setFieldValue(field.name, value);
                }}
                countries={['AL', 'AD', 'AM', 'AT', 'BY', 'BE', 'BA', 'BG', 'CH', 'CY', 'CZ', 'DE',
                    'DK', 'EE', 'ES', 'FO', 'FI', 'FR', 'GB', 'GE', 'GI', 'GR', 'HU', 'HR',
                    'IE', 'IS', 'IT', 'LI', 'LT', 'LU', 'LV', 'MC', 'MK', 'MT', 'NO', 'NL', 'PL',
                    'PT', 'RO', 'RU', 'SE', 'SI', 'SK', 'SM', 'TR', 'UA', 'VA']}
            />
            {form.touched[field.name] && form.errors[field.name] ? <small className="text-danger"> {form.errors[field.name]}</small> : ''}
        </div>
    );
};

export default BootstrapPhoneInput;
