import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointerDetailsAction,
    createClearAppointerResourcesAction,
} from "../../common/actions/Appointer.actions";
import {Spinner} from "../../common/components/Spinner/Spinner";
import ResourceTypes from "../../common/components/ResourceType/ResourceTypeContainer";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";

class PreviewAppointer extends Component {

    componentWillMount() {
        this.props.createClearAppointerResourcesAction();
        this.props.createGetAppointerDetailsAction(this.props.match.params.uuid);
    }

    render() {
        return (
            !this.props.appointerLoading ?
                <div className="appointer appointersList row">
                    <AppointerDetails appointer={this.props.appointer} preview={true} isEmbedded={this.props.isEmbedded}/>
                    <div className="col-12 p-3">
                        <div className="text-center">
                            <h3>
                                <FormattedMessage id="appointersListItem.selectAppointment" defaultMessage="Select appointment"/>
                            </h3>
                        </div>
                    </div>
                    <div className="col-12 p-3">
                        <ResourceTypes preview={true} />
                    </div>
                </div> :
                <Spinner />

        );
    }

}

const mapStateToProps = state => ({
    appointer: state.appointer,
    appointerLoading: state.appointerLoading,
    isEmbedded: state.application.isEmbedded,
});
export default injectIntl(withModal(connect(mapStateToProps, {createGetAppointerDetailsAction,createClearAppointerResourcesAction,})(PreviewAppointer)));
