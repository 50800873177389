import React, {Component} from 'react';

export default class Conditions extends Component {

    render() {
        return (<div className="staticPages">
            <h1>Allgemeine Geschäftsbedingungen (AGB)</h1>

            <h3>1. Allgemeines</h3>
            <ul>
                <li>
                    Die nachstehenden AGB regeln die Bedingungen der Nutzung des Online-Dienstes
                    www.sanasoft.eu (inkl. zugehöriger Subdomains - in weiterer Folge kurz „Sanasoft“
                    genannt), betrieben von TOP-IT e.U. | Simion Hurghis eU mit Sitz in 1020 Wien,
                    Österreich („Betreiber“). Die Nutzung von Sanasoft ist nur auf Grundlage dieser AGB
                    zulässig.
                </li>
            </ul>

            <h3>2. Geltungsbereich</h3>
            <ul>
                <li>
                    ALLE NUTZER VON SANASOFT SIND AN DIESE AGB GEBUNDEN. Die Annahme der AGB
                    durch den Nutzer erfolgt durch Anklicken einer entsprechenden Schaltfläche auf der
                    Benutzeroberfläche oder durch tatsächliche Nutzung der angebotenen Dienste. In jedem
                    Fall erkennt der Nutzer ausdrücklich an, dass seine Nutzung der angebotenen Dienste als
                    Annahme der AGB gilt. Durch die Annahme der AGB kommt eine rechtsverbindliche
                    Vereinbarung (Nutzungsvereinbarung) zwischen dem Nutzer und dem Betreiber
                    hinsichtlich der Nutzung der auf Sanasoft angebotenen Dienste zustande.
                </li>
                <li>
                    Besucher von Sanasoft, die mit den AGB nicht einverstanden sind und/oder diese nicht
                    annehmen können oder wollen, dürfen die angebotenen Dienste nicht nutzen und sind
                    angehalten, die Website zu verlassen.
                </li>
                <li>
                    Die AGB finden auch dann Anwendung, wenn Sanasoft oder auch nur ein Teil davon über
                    andere Webseiten angesteuert und genutzt wird bzw. der Zugriff außerhalb der Republik
                    Österreich erfolgt. Die Nutzung von Sanasoft ist ausgeschlossen, wenn die
                    Inanspruchnahme der angebotenen Dienste nach den Bestimmungen jener
                    Rechtsordnung, welcher der Besucher von Sanasoft unterliegt, verboten ist.
                </li>
                <li>
                    Registrierte Nutzer („Mitglieder“) unterliegen überdies zusätzlichen, vertragsspezifischen
                    Bedingungen, die auch in diesen AGB angeführt sind (siehe Punkt „Mitgliedschaft“).
                </li>
                <li>
                    Für Gesundheitsdienstleister, die das Angebot an Premium-Dienstleistungen in Anspruch
                    nehmen, gelten neben den AGB noch ergänzende Bedingungen laut Vertrag.
                </li>
                <li>
                    Für Kooperationspartner gelten neben den AGB noch ergänzende Bedingungen laut
                    Vertrag.
                </li>
            </ul>

            <h3>3. Dienste von Sanasoft</h3>
            <ul>
                <li>
                    Sanasoft ist ein kostenloses Portal zum Informationsaustausch
                </li>
                <li>
                    Registrierten Nutzern („Mitgliedern“) wird es zudem ermöglicht, anderen Nutzern und
                    Mitgliedern weiterzuempfehlen.
                </li>
                <li>
                    Gesundheitsdienstleistern wird die Möglichkeit geboten, sich und ihre Leistungen zu
                    präsentieren sowie entgeltliche Premium-Dienstleistungen in Anspruch zu nehmen.
                </li>
            </ul>

            <h3>4. Nutzung der Dienste</h3>
            <ul>
                <li>
                    Die Nutzung von Sanasoft und seiner Dienste ist (mit Ausnahme der in Punkt 3.3
                    erwähnten Premium-Dienstleistungen) ausschließlich für private Zwecke gestattet.
                </li>
                <li>
                    Jede über private Zwecke hinausgehende Nutzung von Sanasoft ist unzulässig,
                    insbesondere die kommerzielle Verwendung und Auswertung der von bzw. über Sanasoft
                    angebotenen Daten, sowie - automatisierte Abfragen, Registrierungen, Logins oder
                    Postings. Der Betreiber ist berechtigt, Nutzer/Mitglieder, die gegen das Gebot des
                    privaten Gebrauchs verstoßen, jederzeit und ohne Angabe von Gründen von der Nutzung
                    der angebotenen Dienste auszuschließen. Für den Fall einer kommerziellen Nutzung der
                    von bzw. über Sanasoft angebotenen Daten durch Dritte, stehen dem Betreiber
                    Unterlassungs- und Schadenersatzansprüche zu.
                </li>
                <li>
                    Alle auf Sanasoft eingestellten Daten (Texte, Kommentare, Empfehlungen, AGBs, etc.)
                    inklusive der Bilder, des Layouts und des Logos dürfen ohne im Vorhinein erteilter,
                    schriftlicher Zustimmung des Betreibers nicht verwendet, weitergegeben, vervielfältigt
                    oder anderweitig genützt werden, außer es wird, wie zB. im Pressebereich, explizit darauf
                    hingewiesen.
                </li>
            </ul>

            <h3>5. Mitgliedschaft</h3>
            <ul>
                <li>
                    Eine Mitgliedschaft bei Sanasoft ist KOSTENLOS, und ab dem vollendeten 18.
                    (achtzehnten) Lebensjahr zulässig.
                </li>
                <li>
                    Um eine Mitgliedschaft abzuschließen, registriert sich der Nutzer mit seinen persönlichen
                    Daten (E-Mail Adresse, Geburtsdatum, Geschlecht, Benutzername, Passwort) und stimmt
                    diesen AGB und insbesondere den hier festgelegten Bestimmungen zur Mitgliedschaft
                    zu.
                </li>
                <li>
                    Das Mitglied ist verpflichtet, die Mitgliederregistrierung persönlich auszufüllen, richtige
                    und vollständige Angaben zu leisten, und diese während der gesamten Dauer seiner
                    Mitgliedschaft aktuell zu halten
                </li>
                <li>
                    Der bei der Registrierung gewählte Benutzername darf weder den Bestandteil „Sanasoft“,
                    noch verwechslungsfähig ähnliche Namen beinhalten, und muss den Bedingungen unter
                    Punkt 6 „Einstellen von Daten“ (insbesondere dem Unterpunkt 6.2.) und unter Punkt 7
                    „Qualitätssicherung“ entsprechen.
                </li>
                <li>
                    Pro Person ist nur eine Mitgliedschaft erlaubt.
                </li>
                <li>
                    Die Mitgliedschaft ist höchstpersönlich und nicht übertragbar. Das Mitglied darf sein
                    Passwort nicht an Dritte weitergeben und ist zur sorgfältigen Verwahrung verpflichtet,
                    um Missbräuche durch Dritte auszuschließen.
                </li>
                <li>
                    Den Verlust des Passwortes, dessen Kenntnisnahme durch Dritte sowie jeden Verdacht
                    auf unbefugte Nutzung der Mitgliedschaft muss das Mitglied dem Betreiber unverzüglich
                    unter „Kontakt“ melden. Bis dahin werden alle Aktivitäten auf Sanasoft dem Mitglied
                    zugerechnet, und von ihm verantwortet
                </li>
                <li>
                    Der Betreiber behält sich das Recht vor, seine Rechte und Pflichten gegenüber Nutzern
                    und/oder Mitgliedern teilweise oder ganz auf Dritte zu übertragen.
                </li>
                <li>
                    Das Mitglied hat das Recht seine Mitgliedschaft jederzeit zu kündigen. Hierzu Bedarf es
                    einer Kündigung auf Sanasoft unter „Kontakt“, bei der der Benutzername, das Passwort
                    sowie die registrierte E-Mail Adresse anzugeben sind.
                </li>
            </ul>

            <h3>6. Einstellen von Daten</h3>
            <ul>
                <li>
                    Auf den Seiten von Sanasoft dürfen keine rufschädigende, beleidigende, beschimpfende,
                    verspottende, verleumderische, jugendgefährdende oder sonst rechtswidrige oder
                    bedenkliche Daten (insbesondere im Zusammenhang mit jeder Art der Pornografie, der
                    Gewaltandrohung oder -verherrlichung sowie des politischen oder religiösen
                    Extremismus) eingestellt werden. Auch das Einstellen von sensiblen Daten (Daten
                    natürlicher Personen über ihre rassische und ethnische Herkunft, politische Meinung,
                    Gewerkschaftszugehörigkeit, religiöse oder philosophische Überzeugung) ist untersagt.
                </li>
                <li>
                    <i>Jegliche Art der Werbung auf Sanasoft sei es direkt oder indirekt, verdeckt oder offen,
                    Eigen- oder Fremdwerbung, ohne schriftliche Zustimmung des Betreibers ist NICHT
                    gestattet. Dies inkludiert die Veröffentlichung bzw. das Zugänglichmachen von EmailAdressen, Telefonnummern, Links zu Webseiten Dritter, Logos, Firmennamen,
                        Produktbezeichnungen, Inhalte mit kommerziellem Hintergrund.</i>
                </li>
                <li>
                    Die Abgabe von Bewertungen gegen Entgelt oder sonstige Gegenleistung ist unzulässig.
                </li>
                <li>
                    Nutzer dürfen nur die eigene Meinung veröffentlichen, und nicht die Meinung Dritter. Das
                    Verbreiten von Gerüchten, und von (vermeintlichen) Aussagen Dritter ist nicht gestattet.
                </li>
                <li>
                    Die eingestellten Daten, insbesondere bei Erfahrungsberichten und Empfehlungen,
                    müssen den Tatsachen entsprechen und möglichst sachlich formuliert sein.
                </li>
                <li>
                    Nutzer/Mitglieder, die auch Gesundheitsdienstleister sind, dürfen sich nicht selbst
                    empfehlen bzw. Dritte dazu veranlassen, sie ohne Behandlung weiterzuempfehlen.
                </li>
                <li>
                    Rechte Dritter (wie zum Beispiel Marken-, Urheber-, Patent,- oder Persönlichkeitsrechte)
                    müssen vom Nutzer/Mitglied gewahrt werden und dürfen nicht durch eingestellte Daten
                    von ihm verletzt werden.
                </li>
                <li>
                    Die Nutzer/Mitglieder verpflichten sich, keine Daten (z.B. Viren, Spyware, Trojaner, ...)
                    einzustellen oder Handlungen gegen Sanasoft und/oder den Betreiber vorzunehmen, die
                    zu Schäden des Betriebes von Sanasoft führen können.
                </li>
                <li>
                    Die Nutzer/Mitglieder verpflichten sich, nur wahrheitsgetreue Angaben zu machen, sowie
                    persönliche Daten nur mit ausdrücklichem Einverständnis der betroffenen Person zu
                    veröffentlichen.
                </li>
            </ul>

            <h3>7. Qualitätssicherung</h3>
            <ul>
                <li>
                    Zur Sicherung der Qualität auf Sanasoft sind Nutzer/Mitglieder von Sanasoft angehalten,
                    veraltete, fehlende oder falsche Daten (wie z.B. Kontaktdaten, Ordinationszeiten,
                    Krankenkassen etc.) von Gesundheitsdienstleistern dem Betreiber unter „Kontakt“ oder
                    auf der Arztseite selbst unter „Daten ändern“ zu melden.
                </li>
                <li>
                    Die Nutzer/Mitglieder von Sanasoft sind aus Gründen der Qualitätssicherung weiters
                    aufgefordert, alle verdächtigen Inhalte, die nicht den AGB und Richtlinien entsprechen,
                    unter „Kontakt“ umgehend dem Betreiber zu melden.
                </li>
                <li>
                    Eingegangene Meldungen werden vom Betreiber nach Maßgabe der verfügbaren
                    Ressourcen geprüft und bearbeitet. Sollte sich herausstellen, dass eingestellte Daten
                    nicht den AGB oder den gesetzlichen Bestimmungen entsprechen, steht es im Ermessen
                    des Betreibers, unbeschadet sonstiger Rechte, unzulässige Daten ohne weitere
                    Benachrichtigung zu verändern oder zu entfernen.
                </li>
                <li>
                    Nutzer/Mitglieder, die sich nicht entsprechend der AGB von Sanasoft verhalten,
                    insbesondere bei vorsätzlichen Falschmeldungen und dem Missbrauch des
                    Qualitätssicherungssystems, bzw. gesetzliche Bestimmungen missachten, können vom
                    Betreiber sanktioniert werden. Die Sanktionen können, unbeschadet sonstiger Rechte des
                    Betreibers von einer Verwarnung, über eine vorübergehend Sperre des Mitgliedsprofils
                    bis hin zu einer endgültigen Sperre des Mitgliedsprofils sowie des Zugriffes einer
                    bestimmten IP-Adresse reichen. Damit einhergehend ist eine Löschung aller mit diesen
                    Nutzern/Mitgliedern verbundenen Inhalte und Profile bei Sanasoft ohne weitere
                    Benachrichtigung und ohne Anspruch auf Wiederherstellung möglich. Art und Umfang
                    der jeweiligen Sanktionierung liegt im freien Ermessen des Betreibers.
                </li>
                <li>
                    Der Betreiber behält sich jedenfalls das Recht vor, rechtliche Schritte bei vorsätzlichen
                    Falschmeldungen und dem Missbrauch des Qualitätssicherungssystems zu
                    unternehmen.
                </li>
                <li>
                    Sanasoft hat das Recht, aber nicht die Verpflichtung, die von Nutzern/Mitgliedern
                    eingestellten Daten zu prüfen, insbesondere ob sie den AGB, sowie den einschlägigen
                    gesetzlichen Bestimmungen entsprechen. Der Betreiber ist jedenfalls berechtigt, Daten
                    ohne Nennung von Gründen und ohne weitere Benachrichtigung zu löschen oder zu
                    verändern (z.B. zu anonymisieren oder zu kommentieren).
                </li>
            </ul>

            <h3>8. Datenschutz</h3>
            <ul>
                <li>
                    Dem Betreiber ist Datenschutz ein sehr wichtiges Thema. Selbstverständlich werden
                    daher sämtliche eingestellte Daten nach den einschlägigen Bestimmungen des
                    europäischen und des österreichischen Datenschutzrechts und nach Maßgabe der
                    Sanasoft-„Datenschutzerklärung“ behandelt.
                </li>
            </ul>

            <h3>9. Gewährleistungsausschluss, Haftungsbegrenzung und Schad- und Klagloshaltung</h3>
            <ul>
                <li>
                    Der Betreiber behält sich das Recht vor, übernimmt jedoch keine Verpflichtung, sämtliche
                    einzustellende oder bereits eingestellte Daten vorab und nachträglich durchzusehen, zu
                    prüfen, zu kennzeichnen, zu filtern, zu speichern, abzuändern, abzulehnen oder zu
                    entfernen.
                </li>
                <li>
                    Eingestellte Daten von Nutzern/Mitgliedern, insbesondere Bewertungen und
                    Empfehlungen sowie Erfahrungsberichte, spiegeln deren persönliche Meinung wider, für
                    die diese Nutzer/Mitglieder die alleinige Verantwortung tragen. Der Nutzer/das Mitglied
                    hält den Betreiber schad- und klaglos für jegliche Ansprüche, die, sofern nicht ohnehin
                    ausgeschlossen, von dritter Seite aufgrund der von ihnen eingestellten Inhalte gestellt
                    werden.
                </li>
                <li>
                    Eingestellte Inhalte von Nutzern und Mitgliedern stellen keine professionelle Beratung dar
                    und ersetzen diese auch nicht. Die Verwendung dieser Inhalte zur Entscheidungsfindung
                    für einen geeigneten Gesundheitsdienstleister und die Ableitung eines Gesamteindruckes
                    liegt in der Verantwortung des einzelnen Nutzers und erfolgt auf eigene Gefahr. Der
                    Betreiber übernimmt keine Gewähr für die Verwendung der von seinen Nutzern und
                    Mitgliedern eingestellten Inhalte, und haftet auch nicht für materiellen oder immateriellen
                    Schaden der auf deren Verwendung zurückzuführen ist.
                </li>
                <li>
                    Der Betreiber übernimmt keine Gewährleistung dafür, dass
                    <ul>
                        <li>
                            die Nutzung der Services ununterbrochen, zeitgerecht, sicher und fehlerfrei erfolgen
                            kann (keine Verfügbarkeitszusage)
                        </li>
                        <li>
                            die Nutzung der Services den Anforderungen der Nutzer/Mitglieder entspricht oder
                        </li>
                        <li>
                            die angebotenen Daten richtig und vollständig sind.
                        </li>
                    </ul>
                </li>
                <li>
                    Eine Haftung des Betreibers besteht grundsätzlich nur für eigene Inhalte und ist
                    auf Vorsatz und grobe Fahrlässigkeit beschränkt. Jedenfalls ausgeschlossen wird
                    eine Haftung des Betreibers für Verluste oder Schäden, die Nutzern/Mitgliedern
                    aufgrund folgender Umstände entstehen:
                    <ul>
                        <li>
                            aus Vertrauen auf Richtigkeit und Vollständigkeit der angebotenen Daten
                        </li>
                        <li>
                            aufgrund von Änderungen an den angebotenen Services
                        </li>
                        <li>
                            aufgrund der Löschung, Zerstörung oder nicht erfolgten Speicherung von Daten, die
                            durch die Nutzung der Services aufbewahrt werden
                        </li>
                        <li>
                            aufgrund des Versäumnisses des Nutzers/Mitglieds, korrekte persönliche Daten zur
                            Verfügung zu stellen oder
                        </li>
                        <li>
                            aufgrund des Versäumnisses des Nutzers/Mitglieds für die Sicherheit und
                            Geheimhaltung des Passworts zu sorgen.
                        </li>
                    </ul>
                </li>
                <li>
                    Auf Sanasoft sind auch Links zu Datenquellen Dritter und externen Webseiten Dritter
                    enthalten, auf deren Inhalt der Betreiber keinen bzw. nur bedingten Einfluss hat und
                    deshalb auch keine Gewähr dafür übernehmen kann. Der Betreiber distanziert sich daher
                    von dem Inhalt, der von Dritten angeboten wird. Für die Richtigkeit und Vollständigkeit
                    des Inhaltes der verlinkten Quellen und Seiten sowie deren Verfügbarkeit ist der jeweilige
                    Anbieter verantwortlich.
                </li>
                <li>
                    Da bei Datenübertragungen im Internet das Risiko des Datenverlustes besteht,
                    übernimmt der Betreiber keine Gewähr bei den Datenübermittlungen seiner Nutzer.
                </li>
            </ul>

            <h3>
                10. Änderung der Allgemeinen Geschäftsbedingungen und der Dienste
            </h3>
            <ul>
                <li>
                    Der Betreiber behält sich das Recht vor, Änderungen der Allgemeinen
                    Geschäftsbedingungen einseitig vorzunehmen, sowie sein Angebot an Diensten ohne
                    Angabe von Gründen zu kürzen, zu erweitern oder einzustellen.
                </li>
                <li>
                    Die allgemeine Bekanntgabe von maßgeblichen Änderungen der AGB erfolgt über die
                    vom Mitglied bekanntgegebene Emailadresse, und es liegt in der Verantwortung des
                    Nutzers diese Bekanntgaben wahrzunehmen und sich mit den Veränderungen vertraut zu
                    machen.
                </li>
                <li>
                    Mit der Nutzung der angebotenen Dienste von Sanasoft nach einer erfolgten
                    Aktualisierung der AGB akzeptiert der Nutzer die neuen AGB.
                </li>
            </ul>

            <h3>11. Schlussbestimmungen</h3>
            <ul>
                <li>
                    Sollte eine Bestimmung dieser AGB unwirksam oder undurchsetzbar sein, so gilt die
                    unwirksame oder undurchführbare Regelung durch jene wirksame und durchführbare
                    Regelung als ersetzt, die dem Zweck der ursprünglichen Regelung am Nächsten kommt.
                    Der restliche Teil der AGB bleibt hiervon unberührt und wirksam.
                </li>
                <li>
                    Alle aus einer Nutzung von oder Mitgliedschaft bei Sanasoft abgeleiteten
                    wechselseitigen Rechte und Pflichten sowie Ansprüche zwischen dem Betreiber und
                    einem Nutzer/Mitglied unterliegen österreichischem Recht unter Ausschluss der
                    Kollisionsnormen.
                </li>
                <li>
                    Als ausschließlicher Gerichtsstand für alle sich zwischen dem Betreiber und einem
                    Nutzer/Mitglied ergebenden Rechtsstreitigkeiten wird das in Handelssachen für Wien,
                    Innere Stadt, zuständige Gericht vereinbart. Davon abweichend gilt § 14 KSchG für
                    Streitigkeiten mit Nutzern oder Mitgliedern, die Verbraucher sind. In jedem Fall ist der
                    Betreiber berechtigt, den Nutzer oder das Mitglied auch an seinem allgemeinen
                    Gerichtsstand zu klagen.
                </li>
            </ul>
        </div>);
    }

}