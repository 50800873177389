import React from 'react';
// import { withRouter } from 'react-router';
import logo from '../../images/logo_sanasoft.png';
//import googlePlayBtn from '../../images/googlePlayBtn.png';
//import appStoreBtn from '../../images/appStoreBtn.png';
import {NavLink, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";


const Footer = props => {
    const location = useLocation();
    const pathName = location.pathname.split('/')[1];
    return (
        pathName !== 'preview' ?
            <div className="footer row">
                <div className="col-md-2">
                    <div className="row justify-content-center align-items-center justify-content-md-start logo"> <img src={logo} alt={"Logo"} height="24px" /> </div>
                </div>
                <div className="col-md-8 d-flex justify-content-center align-items-center mb-6">
                    <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                        <NavLink to={'/gdpr'}>
                            <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy policy"/>
                        </NavLink>
                    </div>
                    <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                        <NavLink to={'/gdpr'}>
                            <FormattedMessage id="footer.termsConditions" defaultMessage="Terms and conditions"/>
                        </NavLink>
                    </div>
                    <div className="d-inline-flex justify-content-center align-items-center px-2 px-md-2 px-lg-3">
                        <NavLink to={'/gdpr'}>
                            <FormattedMessage id="footer.impressum" defaultMessage="Impressum"/>
                        </NavLink>
                    </div>
                    <div className="d-inline-flex justify-content-center px-2 px-md-2 px-lg-3">
                        <NavLink to={'/gdpr'}>
                                <FormattedMessage id="footer.contact" defaultMessage="Contact"/>
                        </NavLink>
                    </div>
                </div>
                {/* <div class="col-md-3 justify-content-center d-flex"> <a href="#">
                        <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">

                        </div>
                    </a> <a href="#">
                        <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">

                        </div>
                    </a> <a href="#">
                        <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">

                        </div>
                    </a> <a href="#">
                        <div class="d-inline-flex px-3 px-md-2 px-lg-3 pt-3">
                        </div>
                    </a>
                </div> */}
            </div>

            /* // <div className="footer container-fluid">
            //     <hr />
            //     <div className="container footerContainer">
            //         <div className="row">
            //             <div className="col-md-2 text-left">
            //                 <img class="logo" src={logo} alt={"Logo"}/>
            //             </div>
            //             <div class="col-md-2">
            //                 <NavLink to={'/gdpr'}>
            //                     <FormattedMessage id="footer.privacyPolicy" defaultMessage="Privacy policy"/>
            //                 </NavLink>
            //             </div>
            //             <div class="col-md-2">
            //                 <NavLink to={'/conditions'}>
            //                     <FormattedMessage id="footer.termsConditions" defaultMessage="Terms and conditions"/>
            //                 </NavLink>
            //                 </div>
            //             <div class="col-md-2">
            //                 <NavLink to={'/impressum'}>
            //                     <FormattedMessage id="footer.impressum" defaultMessage="Impressum"/>
            //                 </NavLink>
            //                 </div>
            //             <div class="col-md-2">
            //                 <NavLink to={'/contact'}>
            //                     <FormattedMessage id="footer.contact" defaultMessage="Contact"/>
            //                 </NavLink>
            //             </div>

            //             <div className="col-md-2 text-right appButtons">
            //                 <a href="http://calendar.sanasoft.com/#"><img src={appStoreBtn} alt="App Store" /></a>
            //                 <a href="http://calendar.sanasoft.com/#"><img src={googlePlayBtn} alt="Google play" /></a>
            //             </div>
            //         </div>
            //     </div>
            //</div> */
            : null
    );
};

export default Footer;
