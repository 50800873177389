import { SET_APPOINTMENT_LOADING } from "../actions/Appointments.actions";

const initialState = true;

const AppointmentLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTMENT_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default AppointmentLoadingReducer;
