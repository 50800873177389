import * as React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

export const AlertCancelAppointmentSuccess = (props) => {
    return (
        <div>
            <FormattedMessage id="cancelBookingSuccess" defaultMessage="Appointment canceled" />
        </div>
    );
};
