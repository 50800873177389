import { combineReducers } from 'redux';
// import { connectRouter } from 'connected-react-router';
// import { reducer as FormReducer } from 'redux-form';
import alert from './Alert.reducer';
import application from './Application.reducers';
import appointer from './Appointer.reducers';
import appointers from './Appointers.reducers';
import appointerLoading from './AppointerLoader.reducers';
import appointersListLoading from './AppointersListLoader.reducers';
import appointersSpecialties from './AppointersSpecialties.reducers';
import filterSuggestions from './FilterSuggestions.reducers';
import resourceTypes from './ResourceTypes.reducers';
import appointerAppointments from './AppointerAppointments.reducers';
import appointerDoctors from './AppointerDoctors.reducers';
import appointerSpecialties from './AppointerSpecialties.reducers';
import freeSlots from './AppointmentsFreeSlots.reducers';
import freeSlotsSelectedDate from './FreeSlotsSelectedDate.reducers';
import appointmentLoading from './AppointmentLoader.reducers';
import freeSlotsIntervals from './FreeSlotsIntervals.reducers';
import freeSlotsLoading from './FreeSlotsLoader.reducers';
import freeSlotSelectedInterval from './FreeSlotsSelectedInterval.reducers';
import appointmentSubmitLoading from './AppointmentSubmitLoader.reducers';
import selectedAppointment from './AppointmentSelected.reducers';
import appointment from './Appointment.reducers';
import submittedAppointment from './SubmittedAppointment.reducers';
import passwordReset from './PasswordReset.reducers';
import passwordResetLoading from './PasswordResetLoader.reducers';
import emailReset from './EmailReset.reducers';
import emailResetLoading from './EmailResetLoader.reducers';
import appointerSelectedResource from './AppointerSelectedResource.reducers';
import contact from './Contact.reducers';
import contactLoading from './ContactLoader.reducers';

const rootReducerApp = (history) => combineReducers({
    alert,
    application,
    appointer,
    appointerAppointments,
    appointerDoctors,
    appointerSelectedResource,
    appointerSpecialties,
    appointerLoading,
    appointers,
    appointersListLoading,
    appointersSpecialties,
    appointment,
    appointmentLoading,
    appointmentSubmitLoading,
    contact,
    contactLoading,
    emailReset,
    emailResetLoading,
    filterSuggestions,
    // form: FormReducer,
    freeSlots,
    freeSlotsIntervals,
    freeSlotsLoading,
    freeSlotsSelectedDate,
    freeSlotSelectedInterval,
    passwordReset,
    passwordResetLoading,
    resourceTypes,
    // router: createRouterReducerMapObject(history),
    selectedAppointment,
    submittedAppointment,
})

export default rootReducerApp;