import {
    takeLatest,
    put,
    call,
    select,
} from 'redux-saga/effects';
import moment from "moment";
import {
    createGetAppointmentFreeSlotsReceivedAction,
    createSetFreeSlotsSeletedDateAction,
    createSetAppointmentLoadingAction,
    createGetFreeSlotsDailyIntervalsReceivedAction,
    createSetFreeSlotsLoadingAction,
    createGetFreeSlotsDailyIntervalsAction,
    createGetAppointmentFreeSlotsAction,
    createSetFreeSlotsSeletedIntervalReceivedAction,
    createSetAppointmentSubmitLoadingAction,
    createSetAppointmentsSelectedReceivedAction,
    GET_APPOINTMENT_FREE_SLOTS,
    GET_FREE_SLOTS_DAILY_INTERVALS,
    SET_FREE_SLOT_SELECTED_INTERVAL,
    GET_APPOINTMENT_SUBMIT,
    SET_APPOINTMENT_SELECTED,
    GET_APPOINTMENT_DETAILS,
    GET_APPOINTMENT_VERIFY_CODE,
    DELETE_APPOINTMENT,
    RESEND_SMS_APPONTMENT,
    SET_UNFOLDED_GROUP,
    createGetAppointmentDetailsReceivedAction,
    createGetAppointmentSubmitReceivedAction,
} from "../actions/Appointments.actions";
import {genericErrorHandler} from "./Application.saga";
import * as Appointments from '../api/Appointments.api';
import {APPOINTMENT_STATUS} from "../config/general";
import {showAlert} from "../actions/Alert.actions";
import {ALERT_TYPES} from "../config/alertTypes";
import {createGetAppointerResourceTypesReceivedAction} from "../actions/Appointer.actions";

function* getFreeSlots(action) {
    yield put(createSetAppointmentLoadingAction(true));


    const appointer = yield select((state) => state.appointer);

    const currentDate = moment();
    const startOfMonth = moment(action.payload.date).startOf('month');
    const date = startOfMonth.isBefore(currentDate) ? currentDate : startOfMonth;

    const data = {
        a_id: appointer.appointer.id,
        rta_id: action.payload.rta_id,
        start_date: date.format('YYYY-MM-DD'),
    };

    const response = yield call(Appointments.getAppointmentFreeSlots, data);

    yield put(createGetAppointmentFreeSlotsReceivedAction(response.data.data));
    yield put(createSetFreeSlotsSeletedDateAction(date.format('YYYY-MM-DD')));

    const interval = response.data.data.filter((interval) => interval.date === date.format('YYYY-MM-DD'));

    if(interval && interval.length) {
        yield put(createGetFreeSlotsDailyIntervalsReceivedAction(interval[0]));
    } else {
        yield put(createGetFreeSlotsDailyIntervalsReceivedAction([]));
    }

    yield put(createSetAppointmentLoadingAction(false));
}

function* getFreeSlotsIntervals(action) {
    yield put(createSetFreeSlotsLoadingAction(true));
    const appointmentFreeSlots = yield select((state) => state.freeSlots);
    if(appointmentFreeSlots.length) {
        const interval = appointmentFreeSlots.filter((interval) => interval.date === action.payload.date);

        if(interval && interval[0] && interval[0].intervals.length) {
            yield put(createGetFreeSlotsDailyIntervalsReceivedAction(interval[0]));
            yield put(createSetFreeSlotsSeletedDateAction(action.payload.date));
        } else if (interval && interval[0] && interval[0].intervals.length === 0 ){
            const newDate = moment(action.payload.date);
            newDate.add(action.payload.value >= 0 ? 1 : -1 , 'days');
            yield put(createGetFreeSlotsDailyIntervalsAction({date: newDate.format('YYYY-MM-DD'), value: action.payload.value, rta_id: action.payload.rta_id}));
        } else {
           yield put(createGetAppointmentFreeSlotsAction({date: action.payload.date, rta_id: action.payload.rta_id}));
        }

    }

    yield put(createSetFreeSlotsLoadingAction(false));
}

function* appointmentSubmit(action) {
    yield put(createSetAppointmentSubmitLoadingAction(true));

    const appointerId = yield select((state) => state.appointer.appointer.id);
    const selectedInterval = yield select((state) => state.freeSlotSelectedInterval.slot);
    const selectedAppointment = yield select((state) => state.selectedAppointment);
    const selectedDate = yield select((state) => state.freeSlotsSelectedDate);
    const objData = {
        ...action.payload.data,
        a_id: appointerId,
        rta_id: selectedAppointment.id,
        start_time: `${selectedDate} ${selectedInterval.st}:00`,
        end_time: `${selectedDate} ${selectedInterval.et}:00`,
    };
    const response = yield call(Appointments.submitAppointment, objData);
    yield put (createGetAppointmentSubmitReceivedAction(response.data.data));
    const responseAppoinmentDetail = yield call(Appointments.getAppointmentDetails, response.data.data.booking_id);
    yield put(createGetAppointmentDetailsReceivedAction(responseAppoinmentDetail.data.data));

    const isEmbedded = yield select((state) => state.application.isEmbedded);
    yield put(createSetAppointmentSubmitLoadingAction(false));
    action.payload.navigate(`${isEmbedded ? '/embedded/' : ''}/appointment/verify/${response.data.data.booking_id}`);
}

function* setFreeSlotSelectedInterval(action) {
    yield put(createSetFreeSlotsSeletedIntervalReceivedAction(action.payload));
    const isEmbedded = yield select((state) => state.application.isEmbedded);
    // action.payload.navigate(isEmbedded ? EMBED_APPOINTMENT_CONFIRMATION : APPOINTMENT_CONFIRMATION);
    action.payload.navigate(`/${isEmbedded ? 'embedded/' : ''}appointment/${action.payload.uuid}/${action.payload.rta_id}/${action.payload.date}/${action.payload.slot.st}`);
}

function* setAppointmentSelectedInterval(action) {
    yield put(createSetAppointmentsSelectedReceivedAction(action.payload));
    const isEmbedded = yield select((state) => state.application.isEmbedded);
    action.navigate(`/${isEmbedded ? 'embedded/' : ''}appointments/${action.uiid}/${action.payload.id}`);
}

function* getAppointmentDetails(action) {
    yield put(createSetAppointmentLoadingAction(true));

    const response = yield call(Appointments.getAppointmentDetails, action.payload.uuid);
    yield put(createGetAppointmentDetailsReceivedAction(response.data.data));

    if(response.data.data.status && response.data.data.status === APPOINTMENT_STATUS.confirmed && action.payload.redirect) {
        const isEmbedded = yield select((state) => state.application.isEmbedded);
        action.payload.navigate(`/${isEmbedded ? 'embedded/' : ''}appointment/confirmed/${action.payload.uuid}`);
    }
    yield put(createSetAppointmentLoadingAction(false));
}

function* appointmentVerifyCode(action) {
    yield put(createSetAppointmentLoadingAction(true));

    try{
        const response = yield call(Appointments.verifyCode, action.payload.data);
        const responseDetails = yield call(Appointments.getAppointmentDetails, response.data.data.uuid);

        yield put(createGetAppointmentDetailsReceivedAction(responseDetails.data.data));
        if(responseDetails.data.data.status && responseDetails.data.data.status === APPOINTMENT_STATUS.confirmed) {
            const isEmbedded = yield select((state) => state.application.isEmbedded);
            yield put(createSetAppointmentLoadingAction(false));
            action.payload.navigate(`/${isEmbedded ? 'embedded/' : ''}appointment/confirmed/${response.data.data.uuid}`);
        }
        yield put(showAlert({}));
        yield put(createSetAppointmentLoadingAction(false));
    } catch (e) {
        yield put(showAlert({
            type: ALERT_TYPES.error,
            message: e.response.data.message,
        }));
        yield put(createSetAppointmentLoadingAction(false));
    }
}

function* cancelBooking(action) {
    yield put(createSetAppointmentLoadingAction(true));

    try{
        yield call(Appointments.cancelBooking, action.payload.uuid, action.payload.info);
        const isEmbedded = yield select((state) => state.application.isEmbedded);
        const appointer = yield select((state) => state.appointment.appointer);
        const embeddedAppointerUrl = appointer && appointer.permalink ? `/embedded/${appointer.permalink.split('/')[1]}` : '/';

        const url = isEmbedded ? embeddedAppointerUrl : appointer.permalink;
        yield put(showAlert({
            type: ALERT_TYPES.success,
            isCancelBooking: true,
        }));
        yield put(createSetAppointmentLoadingAction(false))
        const redirectUrl = url || appointer.metadata.permalink;
        action.payload.navigate(`/${redirectUrl}`);
    } catch (e) {
        yield put(showAlert({
            type: ALERT_TYPES.error,
            message: e.response.data.message,
        }));
        yield put(createSetAppointmentLoadingAction(false))
    }
}

function* resendSMS(action) {
    yield put(createSetAppointmentLoadingAction(true));

    try{
        yield call(Appointments.resendSms, action.payload.uuid, action.payload.tel);
        yield put(showAlert({}));
        yield put(createSetAppointmentLoadingAction(false))
    } catch (e) {
        yield put(showAlert({
            type: ALERT_TYPES.error,
            message: e.response.data.message,
        }));
        yield put(createSetAppointmentLoadingAction(false))
    }
}

function* setUnfoldedGroup(action) {
    // yield put(createSetAppointmentLoadingAction(true));

    const resourceType = yield select((state) => state.resourceTypes);
    const newResources = resourceType.map((resource) => {
        if (resource.unfold) {
            resource.unfold = false;
        }
        if(resource.title === action.payload) {
            resource.unfold = true;
        }
        return resource;
    });

    yield put(createGetAppointerResourceTypesReceivedAction(newResources));
}

export function* apppointmentsSaga() {
    yield takeLatest(GET_APPOINTMENT_FREE_SLOTS, genericErrorHandler(getFreeSlots));
    yield takeLatest(GET_FREE_SLOTS_DAILY_INTERVALS, genericErrorHandler(getFreeSlotsIntervals));
    yield takeLatest(SET_FREE_SLOT_SELECTED_INTERVAL, genericErrorHandler(setFreeSlotSelectedInterval));
    yield takeLatest(GET_APPOINTMENT_SUBMIT, genericErrorHandler(appointmentSubmit));
    yield takeLatest(SET_APPOINTMENT_SELECTED, genericErrorHandler(setAppointmentSelectedInterval));
    yield takeLatest(GET_APPOINTMENT_DETAILS, genericErrorHandler(getAppointmentDetails));
    yield takeLatest(GET_APPOINTMENT_VERIFY_CODE, genericErrorHandler(appointmentVerifyCode));
    yield takeLatest(DELETE_APPOINTMENT, cancelBooking);
    yield takeLatest(RESEND_SMS_APPONTMENT, resendSMS);
    yield takeLatest(SET_UNFOLDED_GROUP, setUnfoldedGroup);
}
