import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
    injectIntl,
    defineMessages,
    FormattedMessage,
} from 'react-intl';
import BootstrapInput from "../../common/components/Form/BootstrapInput";
import {NavLink} from "react-router-dom";
import {passwordResetForm} from "../../common/utils/siteValidations";

const EXTRA_TRANSLATIONS = defineMessages({
    placeholderPassword: {
        id: 'passwordResetForm.password',
        defaultMessage: 'new password',
    },
    placeholderConfirmPassword: {
        id: 'passwordResetForm.confirmPassword',
        defaultMessage: 'confirm new password',
    },
});

class PasswordResetFormContainer extends Component {
    renderButtons = () => {
        if (this.props.passwordReset) {
            if (this.props.passwordReset.message && this.props.passwordReset.data) {
                return (
                    <Fragment>
                        <span className="text-success mr-2">{this.props.passwordReset.message}</span>
                        <NavLink to="/"><button type="button" className="btn btn-dark btn-bleumarin"><FormattedMessage id="passwordResetForm.goHome" defaultMessage="Go home" /></button></NavLink>
                    </Fragment>
                );
            } else {
                return (
                    <Fragment>
                        <span className="text-danger mr-2">{this.props.passwordReset.message}</span>
                        <button type="submit" className="btn btn-dark btn-bleumarin"><FormattedMessage id="passwordResetForm.resetPassword" defaultMessage="Reset Password" /></button>
                    </Fragment>
                );

            }

        } else {
            return <button type="submit" className="btn btn-dark btn-bleumarin"><FormattedMessage id="passwordResetForm.resetPassword" defaultMessage="Reset Password" /></button>
        }
    };

    render() {
        const {intl} = this.props;
        return (
            <div className="resetPasswordForm p-3">
                <Formik
                    initialValues={{ password: '', password_confirmation: '' }}
                    validationSchema={passwordResetForm}
                    onSubmit={(values, actions) => {
                        this.props.onSubmitForm(values);
                    }}
                >
                    {(formProps) => {

                        return (
                            <Form>
                            <div className="form-group formHeader col-12">
                                <h3><FormattedMessage  id="passwordResetForm.PasswordReset" defaultMessage="Password Reset" /></h3>
                            </div>
                            <div className="form-group">
                                <div className="row">
                                    <Field
                                        className="col-12 col-md-12 "
                                        name="password"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderPassword)}
                                        type="password"
                                        component={BootstrapInput}
                                    />
                                </div>
                            </div>
                                <div className="form-group">
                                    <div className="row">
                                        <Field
                                            className="col-12 col-md-12"
                                            name="password_confirmation"
                                            placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderConfirmPassword)}
                                            type="password"
                                            component={BootstrapInput}
                                        />
                                    </div>
                            </div>

                                <div className="form-group col-12 formFooter">
                                    <div className="row">
                                        <div className="col-12 mt-3 text-cener">
                                            {
                                                this.renderButtons()
                                            }
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        )}}
                </Formik>
            </div>
        )
    }
}

// const PasswordResetReduxForm = reduxForm({
//     form: 'passwordResetForm',
//     validate: validate('passwordResetForm'),
// })(PasswordResetFormContainer);

const mapStateToProps = state => ({
    // formValues: getFormValues('passwordResetForm')(state),
    // errors: getFormSyncErrors('passwordResetForm')(state),
    // touchedFields: getFormMeta('passwordResetForm')(state),
});

export const PasswordResetForm = injectIntl(connect(mapStateToProps, {})((PasswordResetFormContainer)));
