import React from 'react';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { Field, Form, Formik } from 'formik';
import BootstrapTextarea from "../Form/BootstrapTextArea";
import {cancelBookingForm} from "../../utils/siteValidations";

const ModalBookingCancelFormContainer = (props) => {
        return (
                <div className="modalCancelBooking">
                    <Modal.Header>
                        <Container>
                            <Row className="p-0">
                                <Col>
                                    <h4><FormattedMessage className="text-center" id="cancelBookingModal.title" defaultMessage="Why do you want to cancel this appointment?" /></h4>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Formik
                        initialValues={{ info: ''}}
                        validationSchema={cancelBookingForm}
                        onSubmit={(values, actions) => {
                            props.onSubmitForm(values);
                        }}
                    >
                        {(formProps) => {
                            return (
                                <Form>
                                <Modal.Body className="p-0">
                                    <Container className="p-0">
                                            <div className="col-12">
                                                <Field
                                                    className="required"
                                                    name="info"
                                                    label={<FormattedMessage  id="cancelBookingModal.reason" defaultMessage="Please specify a reason for canceling this appointment" />}
                                                    component={BootstrapTextarea}
                                                />
                                            </div>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Container className="p-0">
                                        <Row>
                                            <div className="col-6 text-left">
                                                <button type="button" onClick={() => props.modal.onHide()} className="btn btn-secondary btn-light-grey btn-gray"><FormattedMessage id="cancelBookingModal.cancel" defaultMessage="Cancel" /></button>
                                            </div>
                                            <div className="col-6 text-right">
                                                <button type="submit" className="btn btn-primary btn-dark btn-dark-blue btnConfirm"><FormattedMessage id="cancelBookingModal.submit" defaultMessage="Submit" /></button>
                                            </div>
                                        </Row>
                                    </Container>
                                </Modal.Footer>
                            </Form>
                        )}}
                    </Formik>
                </div>
        );
}

// const ModalBookingCancelForm = reduxForm({
//     form: 'cancelBookingForm',
//     enableReinitialize: true,
//     validate: validate('cancelBookingForm')
// })(ModalBookingCancelFormContainer);

const mapStateToProps = (state) => ({
});

export default injectIntl(connect(
    mapStateToProps,{}
)(ModalBookingCancelFormContainer));
