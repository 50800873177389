import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
    injectIntl,
    defineMessages,
    FormattedMessage, useIntl,
} from 'react-intl';
import { Link } from "react-router-dom";
// import flags from 'react-phone-number-input/flags';
// import { getCountryCallingCode } from 'libphonenumber-js';
import BootstrapInput from "../../common/components/Form/BootstrapInput";
import {BootstrapRadioButton} from "../../common/components/Form/BootstrapRadioButton";
import BootstrapPhoneInput from "../../common/components/Form/BootstrapPhoneInput";
import {DEFAULT_COUNTRY} from "../../common/config/languages";
import {confirmationForm} from "../../common/utils/siteValidations";
import BootstrapSelectbox from "../../common/components/Form/BootstrapSelectbox";
// import {Redirect} from "react-router";
// import {BootstrapGenderButton} from "../../common/components/Form/BootstrapGenderButton";
// import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';

const EXTRA_TRANSLATIONS = defineMessages({
    placeholderFirstName: {
        id: 'confirmationForm.firstName',
        defaultMessage: 'First Name',
    },
    placeholderLastName: {
        id: 'confirmationForm.lastName',
        defaultMessage: 'Last Name',
    },
    placeholderPhone: {
        id: 'confirmationForm.phone',
        defaultMessage: 'Phone',
    },
    placeholderEmail: {
        id: 'confirmationForm.email',
        defaultMessage: 'Email',
    },
    placeholderTaxNumber: {
        id: 'confirmationForm.taxNumber',
        defaultMessage: 'Tax Number',
    },
    placeholderSocialNumber: {
        id: 'confirmationForm.socialNumber',
        defaultMessage: 'Social Number',
    },
    placeholderMessage: {
        id: 'confirmationForm.message',
        defaultMessage: 'Message',
    },
});

const AppointmentConfirmationFormContainer = (props) => {
    // const [gender, setGender] = useState(0);
    const [customFields, setCustomFields] = useState([]);
    const intl = useIntl();

    useEffect(() => {
        if (props.appointer.appointer.custom_fields) {
            const customFieldsParse = JSON.parse(props.appointer.appointer.custom_fields);
            setCustomFields(Object.entries(customFieldsParse))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // const genderSelected = (value) => {
    //     setGender(value);
    // }

    // const renderPhoneInputFlag = (country) => (
    //     <div>
    //         <span className="country-flag">{flags[country.country]()}</span>
    //         <span className="country-prefix">+{getCountryCallingCode(country.country)}</span>
    //     </div>
    // );

    const customFieldsEntries = (customField) => {
            if( customField[1].type === 'text') {
                return (
                    <Field
                        key={customField[1].label}
                        className="col-12 col-md-6"
                        name={customField[0]}
                        placeholder={customField[1].label}
                        type={customField[1].type}
                        component={BootstrapInput}
                        id={`confirmationForm.${customField[0]}`}
                        // validate={customField[1].required ? [required] : null}
                        minLength={customField[1].minLength}
                        maxLength={customField[1].maxLength}
                        pattern={customField[1].regExp}
                    />
                )
            } else {
                const options = [{
                    key: '',
                    value: '',
                    disabled: true,
                }];

                customField[1].options.forEach(element => {
                    const option = {
                        key: element,
                        value: element
                    };
                    options.push(option);
                });

                return (
                    <Field
                        key={customField[1].label}
                        className="col-12 col-md-6"
                        name={customField[0]}
                        placeholder ={customField[1].label}
                        type={customField[1].type}
                        component={BootstrapSelectbox}
                        values={options}
                        value={customField[1].defaultValue ? customField[1].defaultValue : ''}
                        // validate={customField[1].required ? [required] : null}
                    />
                )
            }
    }

    // render() {
    //     const {handleSubmit, intl} = props;

        let embeddedAppointerUrl  = '/embedded/';
        embeddedAppointerUrl += props.appointer && props.appointer.permalink ? props.appointer.permalink.split('/')[1] : '';
        const url = props.application.isEmbedded  ? embeddedAppointerUrl : props.appointer.permalink;

        return (
            <div className="bookingConfirmationForm">
                <Formik
                    initialValues={{ email: '', firstname: '', lastname: '', gender: '', tel: '', cftaxNumber: '', cfSocialNumber: '' }}
                    validationSchema={confirmationForm}
                    onSubmit={(values, actions) => {
                        props.onSubmitForm(values);
                    }}
                >
                    {(formProps) => {

                        return (
                        <Form>
                            <div className="form-group formHeader col-12">
                                <h4><FormattedMessage  id="confirmationForm.headerText" defaultMessage="to receive your verification code, enter your" /></h4>
                            </div>

                            <div className="form-group col-12 radioToolbar">
                                <div className="row">
                                    <Field
                                        // checked={this.state.gender === 2}
                                        name="gender"
                                        id="female"
                                        checked={parseInt(formProps.values.gender) === 2}
                                        label={<FormattedMessage id="confirmationForm.female" defaultMessage="Mrs." />}
                                        component={BootstrapRadioButton}
                                        type="radio"
                                        // onChange={() => {
                                        //     genderSelected(2);
                                        // }}
                                        value={2}
                                    />

                                    {/*<Field*/}
                                    {/*    // checked={this.state.gender === 2}*/}
                                    {/*    name="gender"*/}
                                    {/*>*/}
                                    {/*    {*/}
                                    {/*        (props) => (*/}
                                    {/*            <BootstrapRadioButton*/}
                                    {/*                name={props.input.name}*/}
                                    {/*                value={props.input.value}*/}
                                    {/*                onChange={props.input.onChange}*/}
                                    {/*            />*/}
                                    {/*        )*/}
                                    {/*    }*/}
                                    {/*</Field>*/}
                                    <Field
                                        // checked={this.state.gender === 1}
                                        name="gender"
                                        id="male"
                                        checked={parseInt(formProps.values.gender) === 1}
                                        label={<FormattedMessage id="confirmationForm.male" defaultMessage="Mr." />}
                                        component={BootstrapRadioButton}
                                        type="radio"
                                        // onChange={() => {
                                        //     genderSelected(1);
                                        // }}
                                        value={1}
                                    />
                                    <Field
                                        // checked={this.state.gender === 3}
                                        name="gender"
                                        id="diverse"
                                        checked={parseInt(formProps.values.gender) === 3}
                                        label={<FormattedMessage id="confirmationForm.diverse" defaultMessage="Diverse" />}
                                        component={BootstrapRadioButton}
                                        type="radio"
                                        // onChange={() => {
                                        //     genderSelected(3);
                                        // }}
                                        value={3}
                                    />
                                    <Field
                                        checked={parseInt(formProps.values.gender) === 4}
                                        name="gender"
                                        id="undefined"
                                        label={<FormattedMessage id="confirmationForm.undefined" defaultMessage="Undefined" />}
                                        component={BootstrapRadioButton}
                                        type="radio"
                                        // onChange={() => {
                                        //     genderSelected(4);
                                        // }}
                                        value={4}
                                    />

                                     {/*<div className="col-12 text-right">

                                        <div className="genderSwitchContainer">
                                            <div className={`genderSwitch genderSwitch-${this.state.gender}`}>
                                                <Field
                                                    checked={this.state.gender === 1}
                                                    name="gender"
                                                    id={`react-switch-new-M`}
                                                    component={BootstrapGenderButton}
                                                    type="range"
                                                    onChange={(e) => {
                                                        this.genderSelected(e);
                                                    }}
                                                    value={this.state.gender}
                                                />

                                            </div>
                                        </div>
                                    </div> */}
                                    {formProps.touched['gender'] && formProps.errors['gender'] ? <small className="text-danger"> {formProps.errors['gender']}</small> : ''}
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <div className="row">
                                    <Field
                                        className="col-12 col-md-6 "
                                        name="firstname"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderFirstName)}
                                        type="text"
                                        component={BootstrapInput}
                                        // validate={required}
                                        // errorMessage={intl.formatMessage({id: 'validation.required'})}
                                    />
                                    <Field
                                        className="col-12 col-md-6"
                                        name="lastname"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderLastName) }
                                        type="text"
                                        component={BootstrapInput}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <div className="row">
                                    <Field
                                        className="col-12 col-md-6"
                                        name="email"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderEmail)}
                                        type="text"
                                        component={BootstrapInput}
                                    />

                                    <Field
                                        className="col-12 col-md-6"
                                        name="tel"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderPhone)}
                                        type="tel"
                                        component={BootstrapPhoneInput}
                                        country={ DEFAULT_COUNTRY }
                                        // options={{
                                        //     international: false,
                                        //     country: DEFAULT_COUNTRY,
                                        //     // flagComponent: (country) => renderPhoneInputFlag(country)
                                        // }}
                                    />
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <div className="row">
                                    {customFields.map( customField => customFieldsEntries(customField))}
                                </div>
                            </div>

                            <div className="form-group col-12 formFooter pt-3 pb-3">
                                <div className="row">
                                    <div className="col-12 col-md-6">
                                        <Link className="btn btn-secondary btn-light-grey btn-gray" to={`/${url}`}><FormattedMessage id="confirmationForm.cancel" defaultMessage="Cancel" /></Link>
                                    </div>

                                    <div className="col-12 col-md-6">
                                        <button type="submit" className="btn button btnRight"><FormattedMessage id="confirmationForm.submit" defaultMessage="Get verification code" /></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}}
                </Formik>
            </div>
        );
}
const mapStateToProps = state => ({
    application: state.application,
    appointer: state.appointer,
});

export const AppointmentConfirmationForm = injectIntl(connect(mapStateToProps)((AppointmentConfirmationFormContainer)));
