import { SET_APPOINTERS_LIST_LOADING } from "../actions/Appointers.actions";

const initialState = true;

const AppointersListLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTERS_LIST_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default AppointersListLoadingReducer;
