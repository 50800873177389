import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { createEmailResetAction } from "../../common/actions/EmailReset.actions";
import {ComponentSpinner} from "../../common/components/Spinner/ComponentSpinner";
import {NavLink} from "react-router-dom";

class EmailReset extends Component {

    componentWillMount() {
        this.props.createEmailResetAction(this.props.match.params.hash);
    }

    render() {
        return (
            <div className="resetPassword row text-right">
                {
                    this.props.loading ?
                        <ComponentSpinner type={"white"} /> :
                        <Fragment>
                            <span className="text-white mr-2">{this.props.emailReset.message}</span>
                            <NavLink to="/"><button type="button" className="btn btn-dark btn-bleumarin"><FormattedMessage id="passwordResetForm.goHome" defaultMessage="Go home" /></button></NavLink>
                        </Fragment>
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    emailReset: state.emailReset,
    loading: state.emailResetLoading,
});
export default injectIntl(connect(mapStateToProps,
    {
        createEmailResetAction,
    })(EmailReset));
