import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import {
    createEmailResetReceivedAction,
    createSetEmailResetLoadingAction,
    SUBMIT_EMAIL_RESET,
} from "../actions/EmailReset.actions";
import * as EmailReset from '../api/EmailReset.api';

function* resetEmail(action) {
    yield put(createSetEmailResetLoadingAction(true));
    try {
        const response  = yield call(EmailReset.resetEmail, action.payload);
        yield put(createEmailResetReceivedAction(response.data));
        yield put(createSetEmailResetLoadingAction(false));
    } catch (e) {
        yield put(createEmailResetReceivedAction(e.response.data));
        yield put(createSetEmailResetLoadingAction(false));
    }
}
export function* emailResetSaga() {
    yield takeLatest(SUBMIT_EMAIL_RESET, resetEmail);
}
