import jstz from 'jstz';
const ct = require('countries-and-timezones');

// !IMPORTANT! When adding new languages, do the following
// <lang-name> - ISO2 of the new language
// a) create config/<lang-name>.json
// b) add it to the structure below
// c) import and add it into config/router.js at line 64
// d) import also the moment language in App.js  at line 4-7 ( import 'moment/locale/ro'; )
export const localeMap = {
  de: 'Deutsch',
  en: 'English',
  fr: 'Français',
  it: 'Italiano',
  ro: 'Romana',
};

// detect the browser languager
const getLangFromBrowser = () => {
  // de-DE, de-AT, ro-RO, etc
  var userLang = navigator.language || navigator.userLanguage;

  // testing
  // return 'en'; // OK
  // return 'ro'; // OK
  // return 'de'; // OK
  // return 'fr'; // not loading
  // return 'it'; // OK

  // fr, it, de, en
  return userLang.substring(0, 2);
}

// https://www.npmjs.com/package/jstz
// https://www.npmjs.com/package/countries-and-timezonesc
const getCountryByPosition = () => {
  // get timezone
  const timezone = jstz.determine();
  // get country by timezone
  const tz = ct.getCountryForTimezone(timezone.name());
  // console.log(timezone);
  // console.log(tz);
  return tz.id;
}

// export const DEFAULT_LANGUAGE = process.env.REACT_APP_DEFAULT_LANGUAGE;
export const DEFAULT_LANGUAGE = getLangFromBrowser();

// export const DEFAULT_COUNTRY = process.env.REACT_APP_DEFAULT_COUNTRY;
export const DEFAULT_COUNTRY = getCountryByPosition();

export const AVAILABLE_LANGUAGES = Object.keys(localeMap);

export const STORAGE_LANGUAGE_KEY = 'language';
