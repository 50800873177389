import axios from 'axios';
import { buildOauthPath } from './Base.api';
import {
    API_CLIENT_SECRET,
    API_PUBLIC_TOKEN_GRANT_TYPE,
    API_CLIENT_ID,
    API_PUBLIC_TOKEN_SCOPE,
} from '../config/api';

export function* getPublicToken() {
    const url = buildOauthPath('/oauth/token');
    const dataObj = {
        client_secret: API_CLIENT_SECRET,
        grant_type: API_PUBLIC_TOKEN_GRANT_TYPE,
        client_id: API_CLIENT_ID,
        scope: API_PUBLIC_TOKEN_SCOPE,
    };
    return yield axios.post(url, dataObj);
}