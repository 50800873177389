import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import moment from "moment";
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointmentDetailsAction,
    createDeleteAppointmentAction,
} from "../../common/actions/Appointments.actions";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import {ComponentSpinner} from "../../common/components/Spinner/ComponentSpinner";
import ModalCenter from "../../common/components/Modal/modalCenter";
import ModalBookingCancel from "../../common/components/ModalBookingCancel/ModalBookingCancel";
import {withRouter} from "../../common/utils/withRouter";

class AppointmentDetailsComponent extends Component {

    componentDidMount() {
        this.props.createGetAppointmentDetailsAction({uuid: this.props.urlParams.uuid, navigate: this.props.navigate});
    }

    cancelBooking = () => {
        this.props.modal.onShow();
    };

    handleCancelSubmit = (data) => {
        const newData = {
            ...data,
            uuid: this.props.urlParams.uuid,
            navigate: this.props.navigate,
        };
        this.props.createDeleteAppointmentAction(newData);
        this.props.modal.onHide();
    };

    render() {
        return (
            <Fragment>
                {
                    !this.props.appointmentLoading ?
                        <div className="appointer appointersList row">
                            <AppointerDetails appointer={this.props.appointment} isEmbedded={this.props.isEmbedded}/>
                            <Fragment>
                                <div className="col-10 offset-1 p-0 row">
                                    <div className="col-12 p-3">
                                        <div className="text-center">
                                            <h3>
                                                <FormattedMessage id="appointmentDetails.getVerificationCode" defaultMessage="appointment details"/>
                                            </h3>
                                        </div>
                                    </div>
                                    <div className="col-12 bookingCodeVerificationForm">
                                        <div className="form-group formHeader col-12">
                                            <div className="row">
                                                <div className="col-12 text-center">
                                                    <h3>
                                                        {moment(this.props.appointment.start_time).format('DD MMM YYYY [-] HH:mm')}
                                                    </h3>
                                                </div>
                                            </div>
                                        </div>x
                                        <div className="form-group col-12">
                                            <div className="row">
                                                <div className="col-12 mt-3 mb-5 text-center appointmentDetailsContent">
                                                    <span className="appointmentTime">
                                                        {
                                                            this.props.appointment.resource_type_appointment.duration < 60 ?
                                                                `${moment.utc(moment.duration(this.props.appointment.resource_type_appointment.duration, "minutes").asMilliseconds()).format("mm[min]")}` :
                                                                `${moment.utc(moment.duration(this.props.appointment.resource_type_appointment.duration, "minutes").asMilliseconds()).format("H[h] mm[min]")}`
                                                        }
                                                    </span>
                                                    <h3 className="appoinmentTitle">{this.props.appointment.resource_type_appointment.title}</h3>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-group formFooter col-12 mb-3 text-right">
                                            <div className="row pt-3">
                                                <div className="col-12 col-md-3 offset-md-9 text-right">
                                                    <button type="button" onClick={this.cancelBooking} className="btn btn-dark btn-dark-blue btnConfirm"><FormattedMessage id="appointmentDetails.cancel" defaultMessage="Cancel" /></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        </div>
                        : <ComponentSpinner type={"white"}/>
                }
                <ModalCenter {...this.props.modal}>
                    <ModalBookingCancel
                        modal={this.props.modal}
                        onSubmitForm={this.handleCancelSubmit}

                    />
                </ModalCenter>
            </Fragment>
        );
    }

}

const mapStateToProps = state => ({
    appointment: state.appointment,
    appointmentLoading: state.appointmentLoading,
    isEmbedded: state.application.isEmbedded,
});

export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointmentDetailsAction,
        createDeleteAppointmentAction,
    })(AppointmentDetailsComponent))));
