import React from 'react';

export default function withModal(WrappedComponent) {
    return class extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                show: false,
            };
        }

        close = (evt) => {
            if (evt) evt.preventDefault();

            this.setState({
                show: false,
            });
        };

        open = (evt) => {
            if (evt) evt.preventDefault();

            this.setState({
                show: true,
            });
        };

        render() {
            return (
                <WrappedComponent
                    {...this.props}
                    modal={{
                        show: this.state.show,
                        onHide: this.close,
                        onShow: this.open,
                    }}
                />
            );
        }
    };
}
