import * as React from 'react';
import {
    FormattedMessage,
} from 'react-intl';

export const Spinner = () => (
    <div className="spinner">
        <div className="spinner-border text-light" role="status">
            <span className="d-block d-sm-none"><FormattedMessage id="spinner.loading" defaultMessage="Loading..."/></span>
        </div>
    </div>
);

