import React from 'react';
import {connect} from 'react-redux';
import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import { Field, Form, Formik } from 'formik';
import BootstrapInput from "../Form/BootstrapInput";

const ModalResendSmsFormContainer = (props) => {
        return (
                <div className="modalResendSms">
                    <Modal.Header>
                        <Container>
                            <Row className="p-0">
                                <Col>
                                    <h3><FormattedMessage  id="resendSmsModal.title" defaultMessage="Resend SMS" /></h3>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Header>
                    <Formik
                        initialValues={{ tel: props.initialValues.tel}}
                        onSubmit={(values, actions) => {
                            props.onSubmitForm(values);
                        }}
                    >
                        {(formProps) => {
                            return (
                                <Form>
                                <Modal.Body className="p-0">
                                    <Container className="p-0">
                                            <div className="col-12">
                                                <Field
                                                    className="required"
                                                    name="tel"
                                                    label={<FormattedMessage  id="resendSms.checkPhoneNumber" defaultMessage="Please check your phone number and click send" />}
                                                    component={BootstrapInput}
                                                />
                                            </div>
                                    </Container>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Container className="p-0">
                                        <Row>
                                            <div className="col-6 text-left">
                                                <button type="button" onClick={() => props.modal.onHide()} className="btn btn-secondary btn-light-grey"><FormattedMessage id="cancelBookingModal.cancel" defaultMessage="Cancel" /></button>
                                            </div>
                                            <div className="col-6 text-right">
                                                <button type="submit" className="btn btn-dark btn-dark-blue btnConfirm"><FormattedMessage id="resendSms.send" defaultMessage="Send sms" /></button>
                                            </div>
                                        </Row>
                                    </Container>
                                </Modal.Footer>
                            </Form>
                            )}}
                    </Formik>
                </div>
        );
}

// const ModalResendSmsForm = reduxForm({
//     form: 'resendSmsForm',
//     enableReinitialize: true,
//     validate: validate('resendSmsForm')
// })(ModalResendSmsFormContainer);

const mapStateToProps = (state) => ({
});

export default injectIntl(connect(
    mapStateToProps,{}
)(ModalResendSmsFormContainer));
