import React from 'react';

export const BootstrapRadioButton = ({field, form, meta, ...props}) => {

  const renderComponent = () => {
      // console.log(form);
    return (
        <input {...field} {...props}/>
    );
  }

  // render() {
    // this.props.input.checked = this.props.checked;

    return (
        // <div className={`form-group ${this.props.meta.touched && this.props.meta.error ? 'has-error' : ''}`}>
        <div className={`col-auto`}>
          <div className="radio">
            {renderComponent()}
            <label htmlFor={props.id}>
              {props.label}
            </label>

          </div>
        </div>
    );
  // }
}
