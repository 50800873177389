export const SUBMIT_EMAIL_RESET = 'SUBMIT_EMAIL_RESET';
export const SUBMIT_EMAIL_RESET_RECEIVED = 'SUBMIT_EMAIL_RESET_RECEIVED';
export const EMAIL_RESET_LOADING = 'EMAIL_RESET_LOADING';

export const createEmailResetAction = (payload) => ({
    type: SUBMIT_EMAIL_RESET,
    payload,
});

export const createEmailResetReceivedAction = (payload) => ({
    type: SUBMIT_EMAIL_RESET_RECEIVED,
    payload,
});

export const createSetEmailResetLoadingAction = (payload) => ({
    type: EMAIL_RESET_LOADING,
    payload,
});