import {
  SET_APPOINTER_SELECTED_RESOURCE_STATE,
  CLEAR_APPOINTER_SELECTED_RESOURCE_STATE,
} from "../actions/Appointer.actions";

const initialState = null;

const AppointerSelectedResourceReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTER_SELECTED_RESOURCE_STATE:
      return {
        ...action.payload,
      };
    case CLEAR_APPOINTER_SELECTED_RESOURCE_STATE:
      return initialState;
    default:
      return state;
  }
};

export default AppointerSelectedResourceReducer;
