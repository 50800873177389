import {
  CLEAR_APPOINTER_RESOURCES,
} from "../actions/Appointer.actions";
import {GET_APPOINTMENT_FREE_SLOTS_RECEIVED} from "../actions/Appointments.actions";

const initialState = [];

const AppointmentsFreeSlotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_FREE_SLOTS_RECEIVED:
      return [
        ...action.payload,
      ];
    case CLEAR_APPOINTER_RESOURCES:
      return initialState;
    default:
      return state;
  }
};

export default AppointmentsFreeSlotsReducer;
