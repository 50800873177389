export const SUBMIT_CONTACT = 'SUBMIT_CONTACT';
export const SUBMIT_CONTACT_RECEIVED = 'SUBMIT_CONTACT_RECEIVED';
export const SUBMIT_CONTACT_LOADING = 'SUBMIT_CONTACT_LOADING';

export const createContactAction = (payload) => ({
    type: SUBMIT_CONTACT,
    payload,
});

export const createContactReceivedAction = (payload) => ({
    type: SUBMIT_CONTACT_RECEIVED,
    payload,
});

export const createSetContactLoadingAction = (payload) => ({
    type: SUBMIT_CONTACT_LOADING,
    payload,
});