import React from 'react';

const FreeSlotsItem = (props) => {
   return (
       <div className="freeSlotBox" onClick={props.onSelectSlot}>
            <div className="freeSlotsItem col-2 btn-cal">
                <span className="text-center">
                    {props.slot.ti}
                </span>
            </div>
       </div>
    );
};

export default FreeSlotsItem;
