import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import {
    createContactReceivedAction,
    createSetContactLoadingAction,
    SUBMIT_CONTACT,
} from "../actions/Contact.actions";
import * as Contact from '../api/Contact.api';

function* contact(action) {
    yield put(createSetContactLoadingAction(true));
    try {
        const response  = yield call(Contact.sendContactForm, action.payload);
        yield put(createContactReceivedAction(response.data));
        yield put(createSetContactLoadingAction(false));
    } catch (e) {
        yield put(createContactReceivedAction(e.response.data));
        yield put(createSetContactLoadingAction(false));
    }
}
export function* contactSaga() {
    yield takeLatest(SUBMIT_CONTACT, contact);
}
