import React, {Component} from 'react';
import Modal from "react-bootstrap/Modal";

class ModalCenter extends Component {

    constructor(props) {
        super(props);
        this.state = {
            show: false,
            isFadeIn: false,
        };
        this.timerIds = [];
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.show === nextProps.show) {
            return;
        }

        this.setState({
            children: nextProps.children,
        });

        if (nextProps.show) {
            this.open();
        } else {
            this.close();
        }
    }

    componentWillUnmount() {
        this.cleanUp();
    }

    setUp() {
        document.addEventListener('keydown', this.onEscKeyDown);
    }

    cleanUp = () => {
        this.setState({ show: false });
        document.removeEventListener('keydown', this.onEscKeyDown);

        this.timerIds.forEach((timerId) => {
            window.clearTimeout(timerId);
        });
    };

    open() {
        this.setUp();
        this.setState({ show: true });
        this.timerIds.push(window.setTimeout(this.fadeIn, 0));
    }

    close() {
        this.setState({
            isFadeIn: false,
            children: null,
        });

        // delay cleanup so animations can complete before closing
        this.timerIds.push(window.setTimeout(this.cleanUp, 300));
    }

    fadeIn = () => {
        this.setState({ isFadeIn: true });
    };

    onEscKeyDown = (evt) => {
        if (evt.keyCode !== 27) return;

        this.props.onHide();
    };

    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                >
                {this.props.children}
            </Modal>
        );
    }
}

export default ModalCenter;