import React, {Component, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import withModal from '../../common/components/Modal/modalHoc';
import {
    createGetAppointmentDetailsAction,
    createGetAppointmentVerifyCodeAction,
} from "../../common/actions/Appointments.actions";
import { ComponentSpinner } from "../../common/components/Spinner/ComponentSpinner";
import AppointerDetails from "../../common/components/AppointerDetails/appointerDetails";
import {APPOINTMENT_STATUS} from "../../common/config/general";
import confirmed from '../../common/images/confirmed.png';
import {withRouter} from "../../common/utils/withRouter";

class AppointmentCodeVerification extends Component {

    componentDidMount() {
        this.props.createGetAppointmentDetailsAction({uuid: this.props.urlParams.uuid, redirect: true, navigate: this.props.navigate});
    }
    renderCalendarButton = () => {
        if (this.props.appointment && this.props.appointment.status === APPOINTMENT_STATUS.confirmed) {
            return (
                <Fragment>
                    <div className="add-to-calendar">
                        <ul>
                            <li> <a href={this.props.appointment.links.google} className='google-link' target="_blank" rel="noopener noreferrer">&nbsp;</a><br />Google </li>
                            <li> <a href={this.props.appointment.links.apple} className='apple-link' target="_blank"  rel="noopener noreferrer" filename="event.ics">&nbsp;</a><br />Apple  </li>
                            <li> <a href={this.props.appointment.links.outlookcom} className='outlookcom-link' target="_blank" rel="noopener noreferrer">&nbsp;</a><br />Outlook   </li>
                        </ul>
                    </div>
                </Fragment>);
        }
        return null;
    }
    render() {
        // const pathName = this.props.location.pathname.split('/')[1];
        // let embeddedAppointerUrl  = '/embedded/';
        // embeddedAppointerUrl += this.props.appointment && this.props.appointment.appointer && this.props.appointment.appointer.metadata ? this.props.appointment.appointer.metadata.permalink.split('/')[1] : '';

        // const {handleSubmit, intl} = this.props;
        // const url = this.props.application.isEmbedded  ? embeddedAppointerUrl : this.props.appointer.permalink;
        // const url = embeddedAppointerUrl; //this.props.appointer.permalink;

        //const logoUrl = pathName === 'embedded' ? embeddedAppointerUrl : '/';
        return (
            !this.props.appointmentLoading ?
                this.props.appointment && this.props.appointment.status === APPOINTMENT_STATUS.pending ?
                    //TODO: fix redirect
                    // <Redirect push to={`/appointment/verify/${this.props.match.params.uuid}`} /> :
                    null :
                    <div className="appointer appointersList row">
                        <AppointerDetails appointer={this.props.appointment} isEmbedded={this.props.isEmbedded}/>
                        <Fragment>
                            <div className="col-10 offset-1 row confirmedAppointment">
                                <div className="col-12 p-3 confirmedContent">
                                    <img src={confirmed} alt="confirmed" />
                                    <FormattedMessage id="confirmedAppointment.confirmed" defaultMessage="Your appointment is confirmed!"/>
                                </div>
                                <div className="col-12 top text-center">
                                    Termin zu deinem Kalender hinzufügen
                                    {this.renderCalendarButton()}
                                </div>
                                <div className="col-12 p-3 bottom text-center">
                                    <FormattedMessage
                                                    id="confirmedAppointment.info"
                                                    defaultMessage="Event details have been sent {email}"
                                                    values={{ email: <b>{this.props.appointment.user.email}</b>, br: <br/> }}
                                                />
                                        {/* <div className="text-left">
                                            <a href="/" className="btn btn-black btn-google-play"> &nbsp </a>
                                            <a href="/" className="btn btn-black btn-app-store"> &nbsp </a>
                                        </div> */}
                                        {/* <div className="text-right">
                                            <a href={logoUrl} className="btn btn-dark btn-dark-blue"><FormattedMessage id="confirmedAppointment.done" defaultMessage="Done" /></a>
                                        </div> */}
                                </div>
                                {/* <div className="col-12 p-3 bottom text-center">
                                    <button type="button" url className="btn button"><FormattedMessage id="confirmedAppointment.bookAnother" defaultMessage="Book another" /></button>
                                    <Link to={`/${url}`}>
                                        <FormattedMessage id="confirmedAppointment.bookAnother" defaultMessage="Book another" />
                                    </Link>
                                </div> */}
                            </div>
                        </Fragment>
                    </div>

            : <ComponentSpinner type="white"/>
        );
    }

}

const mapStateToProps = state => ({
    appointment: state.appointment,
    appointer: state.appointer,
    appointmentLoading: state.appointmentLoading,
    submittedAppointment: state.submittedAppointment,
    isEmbedded: state.application.isEmbedded,
});

export default injectIntl(withModal(withRouter(connect(
    mapStateToProps,
    {
        createGetAppointmentDetailsAction,
        createGetAppointmentVerifyCodeAction,
    })(AppointmentCodeVerification))));
