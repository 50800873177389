import React from 'react';
import {connect} from 'react-redux';
import { Field, Form, Formik } from 'formik';
import {
    injectIntl,
    defineMessages,
    FormattedMessage, useIntl,
} from 'react-intl';
import moment from 'moment';
import BootstrapInput from "../../common/components/Form/BootstrapInput";
import { faCalendarAlt, faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const EXTRA_TRANSLATIONS = defineMessages({
    placeholderVerificationCode: {
        id: 'codeVerificationForm.verificationCode',
        defaultMessage: 'verification code',
    },
});


const AppointmentCodeVerificationFormContainer = (props) => {

    // constructor(props) {
    //     super(props);
    // }

    // render() {
        const intl = useIntl();
        const btnResend = intl.formatMessage({
              id: 'codeVerificationForm.resend'
            , description: "Resend"
            , defaultMessage: "Resend" });

        return (
            <div className="bookingCodeVerificationForm">
                <Formik
                    initialValues={{ code: ''}}
                    onSubmit={(values, actions) => {
                        props.onSubmitForm(values);
                    }}
                >
                    {(formProps) => {

                        return (
                            <Form>
                            <div className="form-group formHeader col-12">
                                <div className="row">
                                    <h2 className="appoinmentTitle">{props.appointment.resource_type_appointment.title}</h2>
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-3 text-center mt-3 mb-3">
                                        <FontAwesomeIcon className="fas" icon={faCalendarAlt} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-9 mt-3 mb-3 text-center text-md-left label">
                                        <h3>
                                           <b>{moment(props.appointment.start_time).format('dddd, DD.MM.YYYY')}</b>
                                        </h3>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-12">
                                <div className="row">
                                    <div className="col-12 col-md-4 col-lg-3 text-center mt-3 mb-3">
                                        <FontAwesomeIcon className="fas" icon={faClock} />
                                    </div>
                                    <div className="col-12 col-md-8 col-lg-9 mt-3 mb-3 text-center text-md-left label">
                                    <h3><b>
                                        {moment(props.appointment.start_time).format('HH:mm')}&nbsp;-&nbsp;
                                            {moment(props.appointment.start_time).add(props.appointment.resource_type_appointment.duration, "minutes").format('HH:mm')}
                                            </b></h3>
                                    </div>
                                </div>
                            </div>

                            <div className="form-group col-12 formFooter pt-3 pb-3 text-center">
                                <div className="row text-center">
                                    <div className="col-2"></div>
                                    <Field
                                        className="col-12  col-sm-12 col-md-4 mb-4 col-lg4 col-xl-4"
                                        name="code"
                                        placeholder={intl.formatMessage(EXTRA_TRANSLATIONS.placeholderVerificationCode)}
                                        type="text"
                                        component={BootstrapInput}
                                        autoFocus
                                    />
                                    <div className="col-12  col-sm-12 col-md-4 mb-4 col-lg4 col-xl-4">
                                        <button type="submit" className="btn btn-dark btn-dark-blue btnConfirm button"><FormattedMessage id="codeVerificationForm.submit"
                                        defaultMessage="Verify code" /></button>
                                    </div>

                                    <div className="col-12 col-md-12 mb-12">
                                        {
                                            props.submittedAppointment && props.submittedAppointment.errors !== '' ?
                                                <div className="text-danger text-center">
                                                    <FormattedMessage
                                                        id="codeVerificationForm.smsError"
                                                        defaultMessage="SMS with verification code could not be send to: {phone} {br} Click resend, verify your phone number and try again."
                                                        values={{ phone: <b>{props.appointment.user.details.tel}</b>, br: <br/> }}
                                                    />
                                                </div> :
                                                <div className="text-success text-center">
                                                    <FormattedMessage
                                                        id="codeVerificationForm.smsSuccess"
                                                        defaultMessage="SMS with verification code was send to: {phone} {br} If you haven't received any SMS, please click {resend}."
                                                        values={{ phone: <b>{props.appointment.user.details.tel}</b>, br: <br/>, resend: <b>{btnResend}</b> }}
                                                    />
                                                </div>
                                        }
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-2"></div>
                                    <div className="col-12  col-sm-12 col-md-4 mb-4 col-lg4 col-xl-4">
                                        <button type="button" onClick={props.cancelBooking} className="btn btn-secondary btn-light-grey btnResend btn-gray">
                                            <FormattedMessage id="codeVerificationForm.cancel" defaultMessage="Cancel"
                                            /></button>
                                    </div>
                                    <div className="col-12  col-sm-12 col-md-4 mb-4 col-lg4 col-xl-4">
                                        <button type="button" onClick={props.resendSms} className="btn btn-secondary btn-light-grey btnResend btn-gray">
                                            <FormattedMessage id="codeVerificationForm.resend" defaultMessage="Resend"
                                            /></button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    )}}
                </Formik>
            </div>
        );
    // }
}

// const CodeVerificationForm = reduxForm({
//     form: 'codeVerificationForm',
//     enableReinitialize: true,
// })(AppointmentCodeVerificationFormContainer);

const mapStateToProps = state => ({
    // formValues: getFormValues('confirmationForm')(state),
});

export const AppointmentCodeVerificationForm = injectIntl(connect(mapStateToProps, {})((AppointmentCodeVerificationFormContainer)));
