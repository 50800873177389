import {
    takeLatest,
    put,
    call,
    select,
} from 'redux-saga/effects';
import {
    createGetAppointerAppointmentResourcesReceivedAction,
    createGetAppointerDetailsReceivedAction,
    createGetAppointerDoctorResourcesReceivedAction,
    createGetAppointerResourceTypesReceivedAction,
    createSetAppointerLoadingAction,
    GET_APPOINTER_DETAILS,
    GET_APPOINTER_DOCTOR_RESOURCES,
    GET_APPOINTER_APPOINTMENT_RESOURCES,
    GET_APPOINTER_SPECIALTY_RESOURCES, createGetAppointerSpecialtyResourcesReceivedAction,
    CLEAR_APPOINTER_SELECTED_RESOURCE, createClearAppointerSelectedResourceStateAction,
    SET_APPOINTER_SELECTED_RESOURCE
} from "../actions/Appointer.actions";
import {genericErrorHandler} from "./Application.saga";
import * as Appointers from '../api/Appointers.api';
import {RESOURCE_TYPES} from "../config/general";

function* getAppointerDetails(action) {
    yield put(createSetAppointerLoadingAction(true));
    const response  = yield call(Appointers.getAppointerDetails, action.payload);
    yield put(createGetAppointerDetailsReceivedAction(response.data.data));
    if (response.data.data.sync_uuid) {
        const resourceTypesResponse = yield call(Appointers.getAppointerResourceTypes, response.data.data.sync_uuid);
        yield put(createGetAppointerResourceTypesReceivedAction(resourceTypesResponse.data.data));
    }
    yield put(createSetAppointerLoadingAction(false));
}

function* getAppointerDoctors() {
    yield put(createSetAppointerLoadingAction(true));
    const doctors  = yield select((state) => state.resourceTypes.filter((resource) => (resource.type === RESOURCE_TYPES.allDoctors || resource.type === RESOURCE_TYPES.doctor) && resource.children && resource.children.length));
    if(doctors.length && doctors[0] && doctors[0].children) {
        yield put(createGetAppointerDoctorResourcesReceivedAction(doctors[0].children));
    }
    yield put(createSetAppointerLoadingAction(false));
}

function* getAppointerAppointments(action) {
    yield put(createSetAppointerLoadingAction(true));
    let appointments  = yield select((state) => state.resourceTypes.filter((resource) => (resource.type === RESOURCE_TYPES.allAppointments || resource.type === RESOURCE_TYPES.appointment) && resource.children && resource.children.length));
    if (action.payload.doctorId) {
        const appointersDoctors = yield select((state) => state.appointerDoctors);
        if ( appointersDoctors.length ) {
            appointments  = appointersDoctors.filter((doctor) => doctor.id === parseInt(action.payload.doctorId) && doctor.children && doctor.children.length);
        } else {
            appointments  = yield select((state) => state.resourceTypes.filter((resource) => resource.id === parseInt(action.payload.doctorId) && (resource.type === RESOURCE_TYPES.allDoctors || resource.type === RESOURCE_TYPES.doctor) && resource.children && resource.children.length));
        }
    }
    if(action.payload.specialtyId) {
        const appointersSpecialties = yield select((state) => state.appointersSpecialties);
        if ( appointersSpecialties.length ) {
            appointments  = appointersSpecialties.filter((specialty) => specialty.id === parseInt(action.payload.specialtyId) && specialty.children && specialty.children.length);
        } else {
            appointments  = yield select((state) => state.resourceTypes.filter((resource) => resource.id === parseInt(action.payload.specialtyId) && (resource.type === RESOURCE_TYPES.allSpecialties || resource.type === RESOURCE_TYPES.speciality) && resource.children && resource.children.length));
        }
    }
    if(appointments.length && appointments[0] && appointments[0].children) {
        yield put(createGetAppointerAppointmentResourcesReceivedAction(appointments[0].children));
    }
    yield put(createSetAppointerLoadingAction(false));
}

function* getAppointerSpecialties() {
    yield put(createSetAppointerLoadingAction(true));
    const specialties  = yield select((state) => state.resourceTypes.filter((resource) => (resource.type === RESOURCE_TYPES.allSpecialties || resource.type === RESOURCE_TYPES.speciality) && resource.children && resource.children.length));
    if(specialties.length && specialties[0] && specialties[0].children) {
        yield put(createGetAppointerSpecialtyResourcesReceivedAction(specialties[0].children));
    }
    yield put(createSetAppointerLoadingAction(false));
}

function* clearAppointerSelectedResource(action) {
    yield put(createClearAppointerSelectedResourceStateAction());
    action.payload.navigate(`/appointer/${action.payload.uuid}`);
}

function SetAppointerSelectedResource(action) {
    action.payload.navigate(`/appointer/${action.payload.resource.uiid}/${action.payload.resource.docid}`);
}

export function* apppointerSaga() {
    yield takeLatest(GET_APPOINTER_DETAILS, genericErrorHandler(getAppointerDetails));
    yield takeLatest(GET_APPOINTER_DOCTOR_RESOURCES, genericErrorHandler(getAppointerDoctors));
    yield takeLatest(GET_APPOINTER_APPOINTMENT_RESOURCES, genericErrorHandler(getAppointerAppointments));
    yield takeLatest(GET_APPOINTER_SPECIALTY_RESOURCES, genericErrorHandler(getAppointerSpecialties));
    yield takeLatest(CLEAR_APPOINTER_SELECTED_RESOURCE, genericErrorHandler(clearAppointerSelectedResource));
    yield takeLatest(SET_APPOINTER_SELECTED_RESOURCE, genericErrorHandler(SetAppointerSelectedResource));
}
