import { SET_APPOINTERS_SPECIALTIES_RECEIVED } from "../actions/Appointers.actions";

const initialState = [];

const AppointersSpecialtiesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTERS_SPECIALTIES_RECEIVED:
      return [
        ...action.payload,
      ];
    default:
      return state;
  }
};

export default AppointersSpecialtiesReducer;
