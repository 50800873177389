import React from 'react';
import { NavLink } from 'react-router-dom'
import {
    FormattedMessage,
} from 'react-intl';
import placeholder from '../../images/placeholder.png';

const AppinterItem = (props) => {
    return (
        <NavLink
            to="/doctor"
            className="appointerItemLink"
        >
            <div className="row m-0">
                <div className="col-4">
                    <img className="appointerItemImage" src={placeholder} alt={props.appointer.name} title={props.appointer.name} />
                </div>
                <div className="col-8 appointerItemDetails">
                    <div className="appointerItemName">{props.appointer.name}</div>
                    <ul className="appinterItemList">
                        <li><span className="listTitle"><FormattedMessage id="appinterItem.speciality" defaultMessage="Speciality"/>: </span><span className="listValue">{props.appointer.speciality}</span></li>
                        <li><span className="listTitle"><FormattedMessage id="appinterItem.clinic" defaultMessage="Clinic"/>: </span><span className="listValue">{props.appointer.clinic}</span></li>
                        <li><span className="listTitle"><FormattedMessage id="appinterItem.address" defaultMessage="Address"/>: </span><span className="listValue">{props.appointer.address}</span></li>
                    </ul>
                </div>
            </div>
        </NavLink>
    );
};

export default AppinterItem;
