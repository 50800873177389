import React from 'react';
import { Alert } from 'react-bootstrap';

const  AlertComponent = (props) => {
    // render() {
        const { children, alert} = props;
        return (
            <Alert variant={alert.type} onClose={() => props.removeAlert()} dismissible>
                {children}
            </Alert>
        );
    // }
}

export default AlertComponent;
