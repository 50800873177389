import { SET_APPOINTER_LOADING } from "../actions/Appointer.actions";

const initialState = true;

const AppointerLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_APPOINTER_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default AppointerLoadingReducer;
