import axios from 'axios';
import { buildPath } from './Base.api';

export function* getAppointers() {
    const url = buildPath('/appointers');
    return yield axios.get(url);
}

export function* getFilteredAppointers(filters) {
    const url = buildPath('/appointers', filters);
    return yield axios.get(url);
}

export function* getAppointerDetails(permalinkId) {
    const url = buildPath(`/metadata/${permalinkId}`);
    return yield axios.get(url);
}

export function* getAppointersSpecialties() {
    const url = buildPath('/specialties');
    return yield axios.get(url);
}

export function* getAppointerResourceTypes(uuid) {
    const url = buildPath(`/get-appointer-resources/${uuid}`);
    return yield axios.get(url);
}