import React from 'react';
// import { withRouter } from 'react-router';
import { createSaveApplicationLocaleAction } from '../../actions/Application.actions';
import Header from "./Header";
import {AVAILABLE_LANGUAGES} from "../../config/languages";
import {useLocation} from "react-router-dom";

export const HeaderContainer = (props) => {
    const location = useLocation();

    const pathName = location.pathname.split('/')[1];
    return (
        pathName !== 'preview' ?
            <Header
                {...props}
                location={location}
                appointer
                application
                createSaveApplicationLocaleAction={createSaveApplicationLocaleAction}
                languages={AVAILABLE_LANGUAGES}
            /> :
            null
    );
}
