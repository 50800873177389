import React, {Fragment, useState} from 'react';
import { useTransition,  animated } from '@react-spring/web';
import ResourceTypeItem from "./ResourceTypeItem";
import ResourceTypeAppointment from "./ResourceTypeAppointment";
import {RESOURCE_TYPES} from "../../config/general";

const ResourceTypesStateless = (props) => {
    const [toggle, set] = useState(true);
    const [toggleSlide, setSlide] = useState(true);
    // const transRef = useSpringRef()
    const transitions = useTransition(toggle,{
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });
    const slideTransition = useTransition(toggleSlide, {
        from: { opacity: 0, transform: 'translate3d(100%,0,0)' },
        enter: { opacity: 1, transform: 'translate3d(0%,0,0)' },
        leave: { opacity: 0, transform: 'translate3d(-50%,0,0)' },
    });

    const renderFoldedResourceTypes = () => {
        if (props.resourceTypes.length) {
            const foldedResources = props.resourceTypes.filter((resource) => !resource.unfold);
            return foldedResources.map((resource, index) => {
                if(resource.children.length === 1) {
                    if (resource.children[0].type !== RESOURCE_TYPES.appointment) {
                        return (
                            <ResourceTypeItem
                                key={index}
                                className="layer1-item"
                                resource={resource.children[0]}
                                appointer={props.appointer}
                                onSelectUnfold={() => {
                                    setSlide(toggleSlide => !toggleSlide);
                                    setTimeout(() => props.selectAppointmentResource({...resource.children[0]}), 500);
                                    setTimeout(() => setSlide(toggleSlide => !toggleSlide), 1000);
                                }
                                }
                            />
                        );
                    } else {
                        return(
                            <ResourceTypeItem
                                key={index}
                                className="layer1-item"
                                resource={resource.children[0]}
                                appointer={props.appointer}
                                onSelectUnfold={() => props.selectAppointment(resource.children[0])}
                            />
                        );

                    }
                }

                return (
                        <ResourceTypeItem
                            key={index}
                            className="layer1-item"
                            resource={resource}
                            appointer={props.appointer}
                            onSelectUnfold={() => {
                                set(state => !state);
                                setTimeout(() => props.selectUnfoldedGroup(resource.title), 500);
                                setTimeout(() => set(state => !state), 1000);
                            }
                            }
                        />
                    );

                }
            );
        }

    };
    const renderUnfoldedResourceTypes = () => {

        if (props.resourceTypes.length) {
            const unfoldedResources = props.resourceTypes.filter((resource) => resource.unfold);
            if (unfoldedResources && unfoldedResources.length > 0) {

                return unfoldedResources[0].children.map((resource, index) => {
                    if (resource.type !== RESOURCE_TYPES.appointment) {
                        return <ResourceTypeItem
                            key={index}
                            className="layer2-item m-0"
                            resource={resource}
                            appointer={props.appointer}
                            onSelectUnfold={() => {
                                setSlide(toggleSlide => !toggleSlide);
                                setTimeout(() => props.selectAppointmentResource({...resource}), 500);
                                setTimeout(()=> setSlide(toggleSlide => !toggleSlide), 1000);
                            }
                            }
                        />
                    } else {
                        return <ResourceTypeAppointment
                            key={index}
                            className="layer2-item"
                            resource={resource}
                            appointer={props.appointer}
                            history={props.history}
                            onSelectAppointment={() => props.selectAppointment(resource)}
                        />
                    }
                });
            }
        }

    };

    const renderResources = () => {

        // if(props.appointerSelectedResource && props.appointerSelectedResource.children && props.appointerSelectedResource.children.length) {
        //     return  (
        //         <Fragment>
        //             <div className="row">
        //                 <div className="backButton" onClick={()=> {
        //                     setSlide(toggleSlide => !toggleSlide);
        //                     setTimeout(() => props.clearSelectedResource(), 500);
        //                     setTimeout(()=> setSlide(toggleSlide => !toggleSlide), 1000);
        //                 }}>
        //                 </div>
        //             </div>
        //             <div className="pt-5">
        //                 {
        //                     transitions((style, item) => (
        //                         item && <animated.div className="trails-text"  style={style}>
        //
        //                             <div className="layer2">
        //                                 {
        //                                     props.appointerSelectedResource.children.map((resource, index) => {
        //                                         return <ResourceTypeAppointment
        //                                             key={index}
        //                                             className="layer2-item"
        //                                             resource={resource}
        //                                             appointer={props.appointer}
        //                                             history={props.history}
        //                                             onSelectAppointment={() => props.selectAppointment(resource)}
        //                                         />
        //                                     })
        //                                 }
        //                             </div>
        //                         </animated.div>
        //                     ))
        //                 }
        //
        //             </div>
        //         </Fragment>
        //     )
        // } else {
            return (
                transitions((style, item) => (
                    item &&
                    <Fragment>
                        <animated.div
                            className="trails-text" style={style} >
                            <div className="layer2">
                                {
                                    renderUnfoldedResourceTypes()
                                }

                            </div>

                            <div className="layer1">
                                {
                                    renderFoldedResourceTypes()
                                }
                            </div>

                        </animated.div>
                    </Fragment>
                ))
                // transitions.map(({ item, key, props }) =>
                //     item &&
                //     <Fragment key={key}>
                //         <animated.div
                //             className="trails-text" key={key} style={props} >
                //             <div className="layer2">
                //                 {
                //                     renderUnfoldedResourceTypes()
                //                 }
                //
                //             </div>
                //
                //             <div className="layer1">
                //                 {
                //                     renderFoldedResourceTypes()
                //                 }
                //             </div>
                //
                //         </animated.div>
                // </Fragment>
                // ));
            )
        // }

    };
    return (
        <Fragment>
            {
                slideTransition((style, item) => (
                    item && <animated.div
                        className="trails-text" style={style} >
                        {
                            renderResources()
                        }
                    </animated.div>
                ))
                // slideTransition.map(({ item, props, key }) => {
                //     console.log("afasdfasdF", item)
                //     return item && <animated.div
                //         className="trails-text" key={key} style={props} >
                //         {
                //             renderResources()
                //         }
                //     </animated.div>
                // })
            }
        </Fragment>
    );
};

export default ResourceTypesStateless;
