import React from 'react';
import {FormattedMessage} from "react-intl";
import {RESOURCE_TYPES_ICONS} from "../../config/general";
import rtAppoitment from "../../images/one_consultation.svg";

const ResourceTypeAppointment = (props) => {
    let iconImg = RESOURCE_TYPES_ICONS[props.resource.type] || rtAppoitment;
    return (
        <div className={props.className}>
            <div className="resourceTypeAppointment" onClick={props.onSelectAppointment}>
                <div className="text-center">
                    <img src={iconImg} alt={props.resource.title}/>
                </div>
                <div className="appointmentDetails">
                    <h5 className="text-center pt-3"> {props.resource.title} </h5>
                    <div className="smallText pt-1">
                        {props.resource.info || ''}
                    </div>
                    <div className="appointmentDuration">
                        {props.resource.duration} &nbsp;
                        <FormattedMessage id="resourceTypeAppointment.minutes" defaultMessage="minutes"/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ResourceTypeAppointment;
