import React, {Component, createRef, Fragment} from 'react';
import { connect } from 'react-redux';
import {
    defineMessages,
    FormattedMessage,
    injectIntl,
} from 'react-intl';
import {Typeahead} from 'react-bootstrap-typeahead';
import Highlight from 'react-highlight'
import appStoreBtn from "../../common/images/appStoreBtn.png";
import googlePlayBtn from "../../common/images/googlePlayBtn.png";
import withModal from '../../common/components/Modal/modalHoc';
import ModalLocation from '../../common/components/ModalLocation/ModalLocation';
import ModalCenter from "../../common/components/Modal/modalCenter";
import {
    createGetAppointersAction,
    createGetFilteredAppointersAction,
    createGetAppointersSpecialtiesAction,
} from "../../common/actions/Appointers.actions";
import {
    createGetFilterSuggestionAction,
    createClearFilterSuggestionsAction,
} from "../../common/actions/FilterSuggestions.actions";
import AppointersList from "../../common/components/AppointersList/appointersList";
import {ComponentSpinner} from "../../common/components/Spinner/ComponentSpinner";
import {mapFilterSuggestions} from "../../common/utils/filterSuggestions";
import {searchBoxIcons, searchBoxItemsType} from "../../common/config/general";
import {withRouter} from "../../common/utils/withRouter";

const EXTRA_TRANSLATIONS = defineMessages({
   searchBoxPlaceholder: {
        id: 'home.searchBox',
        defaultMessage: 'Search for doctors, specialisation or location',
    },
    specialityPlaceholder: {
        id: 'home.speciality',
        defaultMessage: 'filter by specialty',
    },
    noMatchesFound : {
        id: 'home.noMatchesFound',
        defaultMessage: 'No matches found',
    }
});
const ref = createRef();
class Home extends Component {

    componentDidMount() {
        this.props.createGetAppointersSpecialtiesAction();
    }

    searchAppointers(value) {
        const q = value;
        if (q.length > 0) {
            this.props.createGetFilterSuggestionAction({q});
        } else {
            this.props.createClearFilterSuggestionsAction();
        }
    }
    onSelectCategory = (selected) => {
        if (selected && selected[0]) {
            if (parseInt(selected[0].type) !== parseInt(searchBoxItemsType.specialty)) {
                this.props.navigate(selected[0].permalink);
            } else {
                this.props.createGetFilteredAppointersAction({q: selected[0].value});
            }
        }
        if(this.refs.selectSpecialty) {
            this.refs.selectSpecialty.value = "";
        }
    };

    onQuery = (e) => {
        if (e.keyCode === 13) { //enter is pressed
            this.props.createGetFilteredAppointersAction({q: e.target.value});
            if(this.refs.selectSpecialty) {
                this.refs.selectSpecialty.value = "";
            }
            this.refs.typeahead.blur();
        }
    };

    renderSpecialtiesOptions = () => {
        return !this.props.specialties.length ? null :
            this.props.specialties.map((specialty) => (
                <option value={specialty.id} key={specialty.id}>{specialty.title}</option>
            ));
    };

    changeMedialSpeciality = (e) => {
        this.props.createGetFilteredAppointersAction({specialty: e.target.value});
        const instance = this.refs.typeahead;
        instance.clear();
    };

    render() {
        const options = mapFilterSuggestions(this.props.filterSuggestions);
        const inputClassName = this.props.appointers.length ? 'col-12' : 'col-10';
        return (
            <div className="home row">
                {
                    !this.props.appointers.length ?
                        <div className="homeBanner col-md-12">
                            <h1 className="fw-light"><FormattedMessage id="home.headerTitleBook" defaultMessage="Book your"/></h1>
                            <h1 className="fw-bold"><FormattedMessage id="home.headerTitleAppointment" defaultMessage="appointment online"/></h1>
                            <h1 className="fw-bold homeBannerBigFont"><FormattedMessage id="home.headerTitleFast" defaultMessage="fast"/><span><FormattedMessage id="home.headerTitleEr" defaultMessage="er..."/></span></h1>
                        </div> : null
                }

                <div className={`${inputClassName} col-lg-6 form`}>
                    <Typeahead
                        options={options}
                        filterBy={["label"]}
                        labelKey='label'
                        ref={ref}
                        id="searchBoxAppointers"
                        className={'appointerListSearchbox'}
                        onInputChange = {(value)=>this.searchAppointers(value)}
                        onChange={ (item) => this.onSelectCategory(item) }
                        onKeyDown = { (key) => this.onQuery(key)}
                        placeholder={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.searchBoxPlaceholder)}
                        emptyLabel={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.noMatchesFound)}
                        renderMenuItemChildren={ (item, props, index) => {
                            const iconClassName = searchBoxIcons[item.type];
                            return (
                                <Fragment>
                                    <img className="searchBoxImg" src={iconClassName} alt={item.type}/>
                                    <Highlight key={index} search={props.text}>
                                        {item.label}
                                    </Highlight>
                                </Fragment>
                            )
                        }

                        }
                    />
                </div>
                {/*{*/}
                {/*    !this.props.appointers.length ?*/}
                {/*        // <div className="col-2 col-lg-1 locationBtnContainer">*/}
                {/*        //     <button type="button" className="btlLocation d-none d-sm-block"*/}
                {/*        //             onClick={this.props.modal.onShow}/>*/}
                {/*        //     <NavLink className="btlLocation d-sm-none d-block" to={LOCATION_MAP_SEARCH}/>*/}
                {/*        // </div>*/}
                {/*        :*/}
                {/*        <div className="col-12 col-lg-6 locationBtnContainer form">*/}
                {/*        <Form>*/}
                {/*            <Row className="mb-0">*/}
                {/*                <Col>*/}
                {/*                    <Form.Control as="select" className="selectMedicalSpeciality" onChange={(e)=>this.changeMedialSpeciality(e)} ref="selectSpecialty">*/}
                {/*                        <option value=''>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.specialityPlaceholder)}</option>*/}
                {/*                        { this.renderSpecialtiesOptions() }*/}
                {/*                    </Form.Control>*/}
                {/*                </Col>*/}
                {/*            </Row>*/}
                {/*        </Form>*/}

                {/*        </div>*/}
                {/*}*/}
                {
                    this.props.appointers.length ?
                        <div className="col-12">
                            {
                                this.props.appointersListLoading ?
                                    <ComponentSpinner /> :
                                    <AppointersList />
                            }
                        </div> :
                        <div className="col-12">
                            <div className="row mobileSnapshot">
                                <div className="col-6 textSnapshot">
                                    <h1 className="fw-light text-end"><FormattedMessage id="home.headerTitleInstall" defaultMessage="...or install"/></h1>
                                    <h1 className="fw-bold text-end"><FormattedMessage id="home.headerTitleApplication" defaultMessage="application"/></h1>
                                </div>
                                <div className="col-6 appSnapshot">
                                    <span className="applicationSnapshot" />

                                    <div className="appButtons">
                                        <a href="http://calendar.sanasoft.com/#"><img src={appStoreBtn} alt="App Store" /></a>
                                        <a href="http://calendar.sanasoft.com/#"><img src={googlePlayBtn} alt="Google play" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
                <ModalCenter {...this.props.modal}>
                    <ModalLocation modal={this.props.modal}/>
                </ModalCenter>
            </div>
        );
    }

}

const mapStateToProps = state => ({
    appointers: state.appointers,
    appointersListLoading: state.appointersListLoading,
    filterSuggestions: state.filterSuggestions,
    specialties: state.appointersSpecialties,
});
export default injectIntl(withModal(withRouter(connect(mapStateToProps,
    {
        createGetAppointersAction,
        createGetFilteredAppointersAction,
        createGetAppointersSpecialtiesAction,
        createGetFilterSuggestionAction,
        createClearFilterSuggestionsAction,
    })(Home))));
