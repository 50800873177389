import React from 'react';
import { Link } from 'react-router-dom'
import {returnCompleteAddress} from "../../utils/address";
import {getAppointerSpecialties} from "../../utils/specialties";
import doctor from "../../images/one_doctor.svg";
import clinic from "../../images/hospital.svg";

const AppinterListItem = (props) => {
    const imagePlaceholder = parseInt(props.appointer.type) === 1 ? doctor : clinic;
    return (
        <Link
            to={`${props.appointer.metadata.permalink}`}
            className="appointersListItemLink"
        >
            <div className="appointersListItem p-0">
                <div className="p-0 imageContainer">
                    <img className="appointersListItemImage" src={props.appointer.profile_image || imagePlaceholder} alt={props.appointer.title} />
                </div>
                <div className="appointersListItemDetails">
                    <div className="appointersListItemName">
                        {props.appointer.title}
                        {
                            props.appointer.addresses.length ?
                                <span className="appointersListItemAddress text-right"> {returnCompleteAddress(props.appointer.addresses[0])} <i className="locationPin" /></span> : null
                        }
                    </div>
                    <ul className="appointersListItemList">
                        <li><span className="appointersListItemValue">{getAppointerSpecialties(props.appointer.specialties)}</span></li>
                    </ul>
                </div>
            </div>
        </Link>
    );
};

export default AppinterListItem;
