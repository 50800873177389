import React from 'react';
import * as Yup from 'yup';
import {FormattedMessage} from "react-intl";

export const passwordResetForm = Yup.object().shape({
    password: Yup.string()
        .min(8, <FormattedMessage
            id="validation.minLength8"
            defaultMessage="Min. length for this field is 8 characters."
        />)
        .required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    password_confirmation: Yup.string()
        .min(8, <FormattedMessage
            id="validation.minLength8"
            defaultMessage="Min. length for this field is 8 characters."
        />)
        .required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />)
        .oneOf([Yup.ref('password'), null], <FormattedMessage id="validation.passwordNotMatch" defaultMessage="This field is not match with password field" />),
});

export const confirmationForm = Yup.object().shape({
    gender: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    firstname: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    lastname: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    tel: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    // cftaxNumber: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    // cfSocialNumber: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    email: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />)
        .email(<FormattedMessage id="validation.email" defaultMessage="Invalid email address" />),
});
export const cancelBookingForm = Yup.object().shape({
    info: Yup.string()
        .min(10, <FormattedMessage
            id="validation.minLength10"
            defaultMessage="Min. length for this field is 10 characters."
        />)
        .max(500, <FormattedMessage
            id="validation.maxLength500"
            defaultMessage="Max. length for this field is 500 characters."
        />)
        .required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
});

export const contactForm = Yup.object().shape({
    name: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    topic: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    subject: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
    email: Yup.string().required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />)
        .email(<FormattedMessage id="validation.email" defaultMessage="Invalid email address" />),
    text: Yup.string()
        .min(10, <FormattedMessage
            id="validation.minLength10"
            defaultMessage="Min. length for this field is 10 characters."
        />)
        .max(500, <FormattedMessage
            id="validation.maxLength500"
            defaultMessage="Max. length for this field is 500 characters."
        />)
        .required(<FormattedMessage id="validation.required" defaultMessage="This field is required" />),
});
