import {
  SUBMIT_PASSWORD_RESET_RECEIVED,
} from '../actions/PasswordReset.actions';

const initialState = null;

const PasswordResetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_PASSWORD_RESET_RECEIVED:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default PasswordResetReducer;
