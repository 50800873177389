import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    injectIntl,
} from 'react-intl';

import { PasswordResetForm } from "./passwordResetForm";
import { createPassordResetAction } from "../../common/actions/PasswordReset.actions";
import {ComponentSpinner} from "../../common/components/Spinner/ComponentSpinner";
import {withRouter} from "../../common/utils/withRouter";

class PasswordReset extends Component {

    handleSubmit = (data) => {
        const newData = {
            ...data,
            token: this.props.urlParams.hash,
        };

        this.props.createPassordResetAction(newData);
    };

    render() {
        return (
            <div className="resetPassword row">
                {
                    this.props.loading ?
                        <ComponentSpinner type={"white"} /> :

                        <PasswordResetForm
                            onSubmitForm={this.handleSubmit}
                            passwordReset={this.props.passwordReset}
                        />
                }
            </div>
        );
    }

}

const mapStateToProps = state => ({
    passwordReset: state.passwordReset,
    loading: state.passwordResetLoading,
});
export default injectIntl(withRouter(connect(mapStateToProps,
    {
        createPassordResetAction,
    })(PasswordReset)));
