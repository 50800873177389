export const GET_APPOINTMENT_FREE_SLOTS = 'GET_APPOINTMENT_FREE_SLOTS';
export const GET_APPOINTMENT_FREE_SLOTS_RECEIVED = 'GET_APPOINTMENT_FREE_SLOTS_RECEIVED';
export const GET_FREE_SLOTS_DAILY_INTERVALS = 'GET_FREE_SLOTS_DAILY_INTERVALS';
export const GET_FREE_SLOTS_DAILY_INTERVALS_RECEIVED = 'GET_FREE_SLOTS_DAILY_INTERVALS_RECEIVED';
export const SET_FREE_SLOT_SELECTED_DATE = 'SET_FREE_SLOT_SELECTED_DATE';
export const SET_FREE_SLOT_SELECTED_INTERVAL = 'SET_FREE_SLOT_SELECTED_INTERVAL';
export const SET_FREE_SLOT_SELECTED_INTERVAL_RECEIVED = 'SET_FREE_SLOT_SELECTED_INTERVAL_RECEIVED';
export const GET_APPOINTMENT_SUBMIT = 'GET_APPOINTMENT_SUBMIT';
export const GET_APPOINTMENT_SUBMIT_RECEIVED = 'GET_APPOINTMENT_SUBMIT_RECEIVED';
export const SET_APPOINTMENT_LOADING = 'SET_APPOINTMENT_LOADING';
export const SET_FREE_SLOTS_LOADING = 'SET_FREE_SLOTS_LOADING';
export const SET_APPOINTMENT_SUBMIT_LOADING = 'SET_APPOINTMENT_SUBMIT_LOADING';
export const SET_APPOINTMENT_SELECTED = 'SET_APPOINTMENT_SELECTED';
export const SET_APPOINTMENT_SELECTED_RECEIVED = 'SET_APPOINTMENT_SELECTED_RECEIVED';
export const GET_APPOINTMENT_DETAILS = 'GET_APPOINTMENT_DETAILS';
export const GET_APPOINTMENT_DETAILS_RECEIVED = 'GET_APPOINTMENT_DETAILS_RECEIVED';
export const GET_APPOINTMENT_VERIFY_CODE = 'GET_APPOINTMENT_VERIFY_CODE';
export const GET_APPOINTMENT_VERIFY_CODE_RECEIVED = 'GET_APPOINTMENT_VERIFY_CODE_RECEIVED';
export const DELETE_APPOINTMENT = 'DELETE_APPOINTMENT';
export const RESEND_SMS_APPONTMENT = 'RESEND_SMS_APPONTMENT';
export const SET_UNFOLDED_GROUP = 'SET_UNFOLDED_GROUP';


export const createGetAppointmentDetailsAction = (payload) => ({
    type: GET_APPOINTMENT_DETAILS,
    payload
});

export const createGetAppointmentDetailsReceivedAction = (payload) => ({
    type: GET_APPOINTMENT_DETAILS_RECEIVED,
    payload
});

export const createGetAppointmentFreeSlotsAction = (payload) => ({
    type: GET_APPOINTMENT_FREE_SLOTS,
    payload
});

export const createGetAppointmentFreeSlotsReceivedAction = (payload) => ({
    type: GET_APPOINTMENT_FREE_SLOTS_RECEIVED,
    payload
});

export const createSetAppointmentSelectedAction = (payload) => ({
    type: SET_APPOINTMENT_SELECTED,
    payload: payload.data[0],
    uiid: payload.data[1],
    navigate: payload.navigate
});

export const createSetAppointmentsSelectedReceivedAction = (payload) => ({
    type: SET_APPOINTMENT_SELECTED_RECEIVED,
    payload
});

export const createGetFreeSlotsDailyIntervalsAction = (payload) => ({
    type: GET_FREE_SLOTS_DAILY_INTERVALS,
    payload
});

export const createGetFreeSlotsDailyIntervalsReceivedAction = (payload) => ({
    type: GET_FREE_SLOTS_DAILY_INTERVALS_RECEIVED,
    payload
});

export const createSetFreeSlotsSeletedDateAction = (payload) => ({
    type: SET_FREE_SLOT_SELECTED_DATE,
    payload
});

export const createSetFreeSlotsSeletedIntervalAction = (payload) => ({
    type: SET_FREE_SLOT_SELECTED_INTERVAL,
    payload
});

export const createSetFreeSlotsSeletedIntervalReceivedAction = (payload) => ({
    type: SET_FREE_SLOT_SELECTED_INTERVAL_RECEIVED,
    payload
});

export const createGetAppointmentSubmitAction = (payload) => ({
    type: GET_APPOINTMENT_SUBMIT,
    payload
});

export const createGetAppointmentSubmitReceivedAction = (payload) => ({
    type: GET_APPOINTMENT_SUBMIT_RECEIVED,
    payload
});

export const createSetAppointmentLoadingAction = (payload) => ({
    type: SET_APPOINTMENT_LOADING,
    payload
});

export const createSetFreeSlotsLoadingAction = (payload) => ({
    type: SET_FREE_SLOTS_LOADING,
    payload
});

export const createSetAppointmentSubmitLoadingAction = (payload) => ({
    type: SET_APPOINTMENT_SUBMIT_LOADING,
    payload
});

export const createGetAppointmentVerifyCodeAction = (payload) => ({
    type: GET_APPOINTMENT_VERIFY_CODE,
    payload
});

export const createDeleteAppointmentAction = (payload) => ({
    type: DELETE_APPOINTMENT,
    payload
});

export const createResendSmsAppointmentAction = (payload) => ({
    type: RESEND_SMS_APPONTMENT,
    payload
});

export const createSetUnfoldedGroupAction = (payload) => ({
    type: SET_UNFOLDED_GROUP,
    payload
});
