import {
  CLEAR_APPOINTER_RESOURCES,
} from "../actions/Appointer.actions";
import {GET_FREE_SLOTS_DAILY_INTERVALS_RECEIVED} from "../actions/Appointments.actions";

const initialState = null;

const FreeSlotsIntervalsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREE_SLOTS_DAILY_INTERVALS_RECEIVED:
      return {
        ...action.payload,
      };
    case CLEAR_APPOINTER_RESOURCES:
      return initialState;
    default:
      return state;
  }
};

export default FreeSlotsIntervalsReducer;
