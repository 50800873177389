import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import Transition from 'react-transition-group/Transition';
import { removeAlert } from '../../actions/Alert.actions';
import { AlertContent } from './alertContent';
import AlertComponent from './alert';
import {AlertCancelAppointmentSuccess} from "./alertCancelAppointmentSuccess";

const duration = 300;

const defaultStyle = {
     position: 'absolute',
     top: '-100px',
     left: '50%',
     width: '30%',
     display: 'flex',
     alignItems: 'center',
    zIndex: '9000'
};

const transitionStyles = {
    entered: {
        transform: 'translateY(225px)',
        transition: `transform ${duration}ms ease-in-out`
    },
    exiting: {
        transform: 'translateY(-225px)',
        transition: `transform ${duration}ms ease-in-out`
    },
    exited: {
        top: '-100px'
    }
};
const AlertContainer = (props) => {
    const [timeout, setCurrentTimeout] = useState(null);

    useEffect(() => {
        setCurrentTimeout(setTimeout( () => {
            props.removeAlert();
        }, 5000));
        return () => {
            clearTimeout(timeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderAlert = () => (
        props.alert.isCancelBooking ?
            <AlertCancelAppointmentSuccess {...props} /> :
            <AlertContent {...props} />
    );

    const notification = !!(props.alert && props.alert.type);
    return (
        <Transition in={notification} timeout={duration} unmountOnExit>
            {(state) => (
                <div style={{
                    ...defaultStyle,
                    ...transitionStyles[state]
                }}>
                    <div className="alert_container">
                        <AlertComponent {...props}>
                            {renderAlert()}
                        </AlertComponent>
                    </div>
                </div>
            )}
        </Transition>
    );
}

const mapStateToProps = (state) => ({
    alert: state.alert,
});

export default connect(mapStateToProps, { removeAlert })(AlertContainer);
