import * as React from 'react';
import { Provider } from 'react-redux';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/de';
import 'moment/locale/ro';
import store from './store';
import Router from './common/router';
import {BrowserRouter} from "react-router-dom";


function App() {
  return (
      <div>
        <Provider store={store}>
          <BrowserRouter>
            <Router />
          </BrowserRouter>
        </Provider>
      </div>
  );
}

export default App;
