import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import {
    createSetFilterSuggestionAction,
    GET_FILTER_SUGGESTIONS,
} from "../actions/FilterSuggestions.actions";
import {genericErrorHandler} from "./Application.saga";
import * as FilterSuggestions from '../api/FilterSuggestions.api';

function* getFilterSuggestions(action) {
    const response  = yield call(FilterSuggestions.getFilterSuggestions, action.payload);
    yield put(createSetFilterSuggestionAction(response.data.data));

}

export function* filterSuggestionsSaga() {
    yield takeLatest(GET_FILTER_SUGGESTIONS, genericErrorHandler(getFilterSuggestions));
}
