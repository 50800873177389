import { SUBMIT_CONTACT_LOADING } from "../actions/Contact.actions";

const initialState = false;

const ContactLoadingReducer = (state = initialState, action) => {
  switch (action.type) {
    case SUBMIT_CONTACT_LOADING:
      return action.payload;
    default:
      return state;
  }
};

export default ContactLoadingReducer;
