import { SHOW_ALERT, REMOVE_ALERT } from '../actions/Alert.actions';

const initialState = {};

const AlertReducer = (state = initialState, action) => {
    switch (action.type) {
        case SHOW_ALERT:
            return {
                ...action.payload,
            };
        case REMOVE_ALERT: {
            return {};
        }
        default:
            return state;
    }
};

export default AlertReducer;