import React, {Component, Fragment} from 'react';
import moment from 'moment';
import FreeSlotsItem from "./FreeSlotsItem";
import {ComponentSpinner} from "../Spinner/ComponentSpinner";
import {injectIntl} from "react-intl";
import withModal from "../Modal/modalHoc";
import {connect} from "react-redux";
import {
    createGetFreeSlotsDailyIntervalsAction,
    createSetFreeSlotsSeletedIntervalAction,
} from "../../actions/Appointments.actions";
import {withRouter} from "../../utils/withRouter";

class FreeSlotsContainer extends Component {
    updateDate = (value) => {
        const newDate = moment(this.props.freeSlotsSelectedDate);
        newDate.add(value, 'days');
        if(newDate.isSameOrAfter(moment())) {
            this.props.createGetFreeSlotsDailyIntervalsAction({
                date: newDate.format('YYYY-MM-DD'),
                value,
                rta_id: this.props.rta_id
            });
        }
    };

    selectFreeSlot = (slot) => {
        this.props.createSetFreeSlotsSeletedIntervalAction({slot: slot, date: moment(this.props.freeSlotsSelectedDate).format("YYYY-MM-DD"), rta_id: this.props.rta_id, uuid: this.props.uuid, navigate: this.props.navigate});
    };

    renderFreeSlots = () => {
        return this.props.freeSlots.intervals && this.props.freeSlots.intervals.length ?
            this.props.freeSlots.intervals.map((slot, index) => (
                <FreeSlotsItem key={index} slot={slot} onSelectSlot={() => this.selectFreeSlot(slot)} />
            )) : null ;
    };

    render() {
        // const {handleSubmit, intl} = this.props;
        // const headerFormat = intl.formatMessage({
        //       id: 'calendarFreeSlots.header.format'
        //     , description: "Do [of] MMM YYYY"
        //     , defaultMessage: "Do [of] MMM YYYY" });
        // console.log(headerFormat);

        return (
            <Fragment>
            {
                this.props.freeSlotsSelectedDate ?
                    <div className="freeSlotsContainer row">
                        <div className="fsHeader col-12">
                    <span className="prevDate text-left" onClick={() => this.updateDate(-1)}>
                        &#8249;
                    </span>
                            <span className="freeSloteDayString text-center" tabIndex="0">
                        {moment(this.props.freeSlotsSelectedDate).format("DD. MMM YYYY")}
                    </span>
                            <span className="nextDate text-end" onClick={() => this.updateDate(1)}>
                      &#8250;
                    </span>
                        </div>
                        <div className="fsContent col-12 text-justify">
                            { this.props.loading ?
                                <ComponentSpinner type={'white'}/> :
                                this.renderFreeSlots()
                            }
                        </div>
                    </div>
                    : null
            }
            </Fragment>
        );
    }
}
const mapStateToProps = state => ({
    freeSlotsSelectedDate: state.freeSlotsSelectedDate,
    freeSlots: state.freeSlotsIntervals,

});
export default injectIntl(withModal(withRouter(connect(
    mapStateToProps, {createGetFreeSlotsDailyIntervalsAction, createSetFreeSlotsSeletedIntervalAction})(FreeSlotsContainer))));
