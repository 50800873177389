export const GET_FILTER_SUGGESTIONS = 'GET_FILTER_SUGGESTIONS';
export const GET_FILTER_SUGGESTIONS_RECEIVED = 'GET_FILTER_SUGGESTIONS_RECEIVED';
export const CLEAR_FILTER_SUGGESTIONS = 'CLEAR_FILTER_SUGGESTIONS';

export const createGetFilterSuggestionAction = (payload) => ({
    type: GET_FILTER_SUGGESTIONS,
    payload,
});

export const createSetFilterSuggestionAction = (payload) => ({
    type: GET_FILTER_SUGGESTIONS_RECEIVED,
    payload,
});

export const createClearFilterSuggestionsAction = () => ({
    type: CLEAR_FILTER_SUGGESTIONS,
});