import { getItemFromStorage } from './storage';
import { AVAILABLE_LANGUAGES, STORAGE_LANGUAGE_KEY } from '../config/languages';

export const detectLocale = ()=> {
  const locale = getItemFromStorage(STORAGE_LANGUAGE_KEY) ||
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    // $FlowFixMe
    navigator.userLanguage;

  return AVAILABLE_LANGUAGES.find((l) => {
    if (locale) {
      return locale.startsWith(l);
    }
    return false;
  }) || '';
};
