import {
  CLEAR_APPOINTER_RESOURCES,
} from "../actions/Appointer.actions";
import {
  GET_APPOINTMENT_SUBMIT_RECEIVED,
} from "../actions/Appointments.actions";

const initialState = null;

const SubmittedAppointment = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_SUBMIT_RECEIVED:
      return { ...action.payload };
    case CLEAR_APPOINTER_RESOURCES:
      return initialState;
    default:
      return state;
  }
};

export default SubmittedAppointment;
