import {
    takeLatest,
    put,
    call,
} from 'redux-saga/effects';
import {
    createPasswordResetReceivedAction,
    createSetPasswordResetLoadingAction,
    SUBMIT_PASSWORD_RESET,
} from "../actions/PasswordReset.actions";
import * as PasswordReset from '../api/PasswordReset.api';

function* resetPassword(action) {
    yield put(createSetPasswordResetLoadingAction(true));
    try {
        const response  = yield call(PasswordReset.resetPassword, action.payload);
        yield put(createPasswordResetReceivedAction(response.data));
        yield put(createSetPasswordResetLoadingAction(false));
    } catch (e) {
        yield put(createPasswordResetReceivedAction(e.response.data));
        yield put(createSetPasswordResetLoadingAction(false));
    }
}
export function* passwordResetSaga() {
    yield takeLatest(SUBMIT_PASSWORD_RESET, resetPassword);
}
