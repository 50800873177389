export const GET_APPOINTER_DETAILS = 'GET_APPOINTER_DETAILS';
export const GET_APPOINTER_DETAILS_RECEIVED = 'GET_APPOINTER_DETAILS_RECEIVED';
export const GET_APPOINTER_RESOURCE_TYPES = 'GET_APPOINTER_RESOURCE_TYPES';
export const GET_APPOINTER_RESOURCE_TYPES_RECEIVED = 'GET_APPOINTER_RESOURCE_TYPES_RECEIVED';
export const GET_APPOINTER_DOCTOR_RESOURCES = 'GET_APPOINTER_DOCTORS_RESOURCES';
export const GET_APPOINTER_DOCTOR_RESOURCES_RECEIVED = 'GET_APPOINTER_DOCTOR_RESOURCES_RECEIVED';
export const GET_APPOINTER_SPECIALTY_RESOURCES = 'GET_APPOINTER_SPECIALTY_RESOURCES';
export const GET_APPOINTER_SPECIALTY_RESOURCES_RECEIVED = 'GET_APPOINTER_SPECIALTY_RESOURCES_RECEIVED';
export const GET_APPOINTER_APPOINTMENT_RESOURCES = 'GET_APPOINTER_APPOINTMENT_RESOURCES';
export const GET_APPOINTER_APPOINTMENT_RESOURCES_RECEIVED = 'GET_APPOINTER_APPOINTMENT_RESOURCES_RECEIVED';
export const SET_APPOINTER_LOADING = 'SET_APPOINTER_LOADING';
export const CLEAR_APPOINTER_RESOURCES = 'CLEAR_APPOINTER_RESOURCES';
export const SET_APPOINTER_SELECTED_RESOURCE = 'SET_APPOINTER_SELECTED_RESOURCE';
export const SET_APPOINTER_SELECTED_RESOURCE_STATE = 'SET_APPOINTER_SELECTED_RESOURCE_STATE';
export const CLEAR_APPOINTER_SELECTED_RESOURCE = 'CLEAR_APPOINTER_SELECTED_RESOURCE';
export const CLEAR_APPOINTER_SELECTED_RESOURCE_STATE = 'CLEAR_APPOINTER_SELECTED_RESOURCE_STATE';


export const createGetAppointerDetailsAction = (payload) => ({
    type: GET_APPOINTER_DETAILS,
    payload
});
export const createGetAppointerDetailsReceivedAction = (payload) => ({
    type: GET_APPOINTER_DETAILS_RECEIVED,
    payload,
});

export const createGetAppointerResourceTypesAction = (payload) => ({
    type: GET_APPOINTER_RESOURCE_TYPES,
    payload,
});

export const createGetAppointerResourceTypesReceivedAction = (payload) => ({
    type: GET_APPOINTER_RESOURCE_TYPES_RECEIVED,
    payload,
});

export const createSetAppointerLoadingAction = (payload) => ({
    type: SET_APPOINTER_LOADING,
    payload,
});

export const createGetAppointerDoctorResourcesAction = () => ({
    type: GET_APPOINTER_DOCTOR_RESOURCES,
});

export const createGetAppointerDoctorResourcesReceivedAction = (payload) => ({
    type: GET_APPOINTER_DOCTOR_RESOURCES_RECEIVED,
    payload,
});

export const createGetAppointerSpecialtyResourcesAction = () => ({
    type: GET_APPOINTER_SPECIALTY_RESOURCES,
});

export const createGetAppointerSpecialtyResourcesReceivedAction = (payload) => ({
    type: GET_APPOINTER_SPECIALTY_RESOURCES_RECEIVED,
    payload,
});

export const createGetAppointerAppointmentResourcesAction = (payload) => ({
    type: GET_APPOINTER_APPOINTMENT_RESOURCES,
    payload,
});

export const createGetAppointerAppointmentResourcesReceivedAction = (payload) => ({
    type: GET_APPOINTER_APPOINTMENT_RESOURCES_RECEIVED,
    payload,
});

export const createClearAppointerResourcesAction = () => ({
    type: CLEAR_APPOINTER_RESOURCES,
});

export const createSetAppointerSelectedResourceStateAction = (payload) => ({
    type: SET_APPOINTER_SELECTED_RESOURCE_STATE,
    payload,
});

export const createSetAppointerSelectedResourceAction = (payload) => ({
    type: SET_APPOINTER_SELECTED_RESOURCE,
    payload,
});

export const createClearAppointerSelectedResourceStateAction = () => ({
    type: CLEAR_APPOINTER_SELECTED_RESOURCE_STATE,
});

export const createClearAppointerSelectedResourceAction = (payload) => ({
    type: CLEAR_APPOINTER_SELECTED_RESOURCE,
    payload,
});
