import url from 'url';
import querystring from 'querystring';
import { API_HOST, OAUTH_HOST, API_PROTOCOL } from '../config/api';

export function buildPath(resource, parameters = {}) {
    const currentRequest = {
        pathname: resource,
        protocol: API_PROTOCOL,
        host: API_HOST,
        search: querystring.stringify(parameters),
    };
    return url.format(currentRequest);
}

export function buildOauthPath(resource, parameters = {}) {
    const currentRequest = {
        pathname: resource,
        protocol: API_PROTOCOL,
        host: OAUTH_HOST,
        search: querystring.stringify(parameters),
    };
    return url.format(currentRequest);
}