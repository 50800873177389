import { legacy_createStore as createStore, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import createRootReducer  from './common/reducers/index';
import createSagaMiddleware from "redux-saga";
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import { createBrowserHistory } from 'history';
import rootSaga from "./common/sagas/root";

const history = createBrowserHistory();
const sagaMiddleware = createSagaMiddleware();
const initialState = {};
const store = createStore(
    createRootReducer(history),
    initialState,
    composeWithDevTools(applyMiddleware(createRouterMiddleware(history), sagaMiddleware))
)

sagaMiddleware.run(rootSaga);

export default store;
