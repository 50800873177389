import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
    injectIntl,
} from 'react-intl';

import { createContactAction } from "../../common/actions/Contact.actions";
import {ComponentSpinner} from "../../common/components/Spinner/ComponentSpinner";

class Contact extends Component {

    handleSubmit = (data) => {
        this.props.createContactAction(data);
    };

    render() {
        return (
                this.props.loading ?
                    <ComponentSpinner type={"white"} /> :

                    <div className="staticPages">
                        {/*<ContactForm*/}
                        {/*    onSubmit={this.handleSubmit}*/}
                        {/*    contact={this.props.contact}*/}
                        {/*/>*/}
                    </div>
        );
    }

}

const mapStateToProps = state => ({
    contact: state.contact,
    loading: state.contactLoading,
});
export default injectIntl(connect(mapStateToProps,
    {
        createContactAction,
    })(Contact));
