import { GET_APPOINTER_DETAILS_RECEIVED } from "../actions/Appointer.actions";

const initialState = {};

const AppointerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTER_DETAILS_RECEIVED:
      return {
        ...action.payload,
      };
    default:
      return state;
  }
};

export default AppointerReducer;
