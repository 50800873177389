import { all } from 'redux-saga/effects';
import { applicationSaga } from './Application.saga';
import { apppointersSaga } from "./Appointers.saga";
import { apppointerSaga } from './Appointer.saga';
import { filterSuggestionsSaga } from './FilterSuggestions.saga';
import { apppointmentsSaga } from './Appointments.saga';
import { passwordResetSaga } from './PasswordReset.saga';
import { emailResetSaga } from './EmailReset.saga';
import { contactSaga } from './Contact.saga';

export default function* rootSaga() {
    yield all([
        applicationSaga(),
        apppointersSaga(),
        apppointerSaga(),
        apppointmentsSaga(),
        filterSuggestionsSaga(),
        passwordResetSaga(),
        emailResetSaga(),
        contactSaga(),
    ])
}