import React from 'react';

 const  BootstrapInput = ({field, form, meta, ...props}) => {
    // render() {
     return (
            <div className={`
                form-group group
                ${props.className}
                ${form.touched[field.name] && form.errors[field.name] ? 'has-error' : '' }
            `}>
                {props.label ? <label className="control-label" htmlFor={props.id}>
                    {props.label}
                </label> : null }
                <input {...field} {...props} />
                {form.touched[field.name] && form.errors[field.name] ? <small className="text-danger"> {form.errors[field.name]}</small> : ''}
            </div>
        );
    // }
}
export default BootstrapInput;
