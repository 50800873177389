import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {getItemFromStorage} from "../../utils/storage";
import {DEFAULT_LANGUAGE, localeMap, STORAGE_LANGUAGE_KEY} from "../../config/languages";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from '../../images/logo_sanasoft.png';

const Header = props => {

    const switchLanguage = (locale) => {        
        props.createSaveApplicationLocaleAction(locale);
    };

    const storageLang = getItemFromStorage(STORAGE_LANGUAGE_KEY);
    const selectedLang = storageLang ? props.application.locale : DEFAULT_LANGUAGE;
    const languagesArr = props.languages;
    const pathName = props.location.pathname.split('/')[1];

    let embeddedAppointerUrl  = '/embedded/';

    embeddedAppointerUrl += props.appointer && props.appointer.permalink ? props.appointer.permalink.split('/')[1] : '';

    const logoUrl = pathName === 'embedded' ? embeddedAppointerUrl : '/';

    const languages = languagesArr.filter((language) => language !== selectedLang);


    const languageSwitch = languages.length > 0 ? (
        <Navbar expand="lg">
            <a className="navbar-brand logo" href={logoUrl}>
                <img src={logo} alt={"Logo"}/>
            </a>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav" className="languageSwitcher">
                <Nav>
                    <NavDropdown title={localeMap[props.application.locale]} id="basic-nav-dropdown">
                        {languages.map((lang) => (<NavDropdown.Item key={lang} role="button" onClick={() => switchLanguage(lang)}>{localeMap[lang]}</NavDropdown.Item>))}
                    </NavDropdown>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    ) : null;

    return (
        <div className="header">
            <div className="container">
                {languageSwitch}
            </div>
        </div>
    );
};

export default Header;
