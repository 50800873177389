export const APPLICATION_INIT = 'APPLICATION_INIT';
export const SAVE_APPLICATION_LOCALE = 'SAVE_APPLICATION_LOCALE';
export const SET_APPLICATION_LANGUAGE = 'SET_APPLICATION_LANGUAGE';
export const SET_APPLICATION_LOADING = 'SET_APPLICATION_LOADING';
export const SET_EMBEDDED_APPLICATION = 'SET_EMBEDDED_APP';

export const createApplicationInitAction = () => ({
    type: APPLICATION_INIT,
});
export const createSetApplicationLanguageAction = (payload) => ({
    type: SET_APPLICATION_LANGUAGE,
    payload,
});

export const createSaveApplicationLocaleAction = (payload) => ({
    type: SAVE_APPLICATION_LOCALE,
    payload,
});

export const createSetApplicationLoadingAction = (payload) => ({
    type: SET_APPLICATION_LOADING,
    payload,
});

export const createSetEmbeddedApplicationAction = (payload) => ({
    type: SET_EMBEDDED_APPLICATION,
    payload,
});